import type { AppSettings } from "~/store/app";
import { views } from "~/store/app.types";

export function updateDefaultViews(settings: PartialNonNullable<AppSettings>) {
  if ("defaultView" in settings) {
    settings.selectedView =
      views.find((e) => e.groupBy === settings["defaultView"]) ?? views[1];

    delete settings.defaultView;
  }
  if ("defaultSplitBy" in settings) {
    const savedSetting = settings["defaultSplitBy"] as any;

    if (
      ["prime_broker", "fund", "account", "currency", null].includes(
        savedSetting
      )
    ) {
      settings.splitBy = savedSetting;
    }

    delete settings.defaultSplitBy;
  }

  return settings;
}
