import { onBeforeUnmount } from "vue";

type Callback = (...args: any[]) => any;
type EventName = string;
/**
 * Replacement for the Vue 2-based EventBus.
 *
 * https://stackoverflow.com/questions/63471824/vue-js-3-event-bus
 *
 */
export class EventBus {
  eventListeners: Map<EventName, { callback: Callback; once: boolean }[]>;

  constructor() {
    this.eventListeners = new Map();
  }

  registerEventListener(eventName: string, callback: Callback, once = false) {
    if (!this.eventListeners.has(eventName)) {
      this.eventListeners.set(eventName, []);
    }

    const eventListeners = this.eventListeners.get(eventName);
    if (!eventListeners) return;

    eventListeners.push({ callback, once });
  }

  /**
   * See: https://v2.vuejs.org/v2/api/#vm-on
   *
   * Listen for a custom event on the current vm. Events can be triggered by vm.$emit. The callback will receive all the additional arguments passed into these event-triggering methods.
   *
   */
  $on(
    eventNameOrNames: string | string[],
    callback: Callback,
    destroyOnUnmounted = true
  ) {
    const eventNames = Array.isArray(eventNameOrNames)
      ? eventNameOrNames
      : [eventNameOrNames];

    for (const eventName of eventNames) {
      this.registerEventListener(eventName, callback);
    }
    if (destroyOnUnmounted) {
      /**
       * Use beforeUnmount so that event binding/unbinding does not clash when switching
       * between two components with a shared event name
       */
      onBeforeUnmount(() => this.$off(eventNames, callback));
    }
  }

  /**
   * See: https://v2.vuejs.org/v2/api/#vm-once
   *
   * Listen for a custom event, but only once. The listener will be removed once it triggers for the first time.
   *
   */
  $once(eventName: string, callback: Callback) {
    const once = true;
    this.registerEventListener(eventName, callback, once);
  }

  /**
   * If no arguments are provided, remove all event listeners;
   *
   * If only the event is provided, remove all listeners for that event;
   *
   * If both event and callback are given, remove the listener for that specific callback only.
   *
   * See: https://v2.vuejs.org/v2/api/#vm-off
   *
   */
  $off(eventNameOrNames?: string | string[], callback?: CallableFunction) {
    // If no arguments are provided, remove all event listeners;
    if (eventNameOrNames === undefined) {
      this.eventListeners.clear();
      return;
    }

    const eventNames = Array.isArray(eventNameOrNames)
      ? eventNameOrNames
      : [eventNameOrNames];

    for (const eventName of eventNames) {
      const eventListeners = this.eventListeners.get(eventName);

      if (eventListeners === undefined) {
        continue;
      }

      if (typeof callback === "function") {
        for (let i = eventListeners.length - 1; i >= 0; i--) {
          if (eventListeners[i].callback === callback) {
            eventListeners.splice(i, 1);
          }
        }
      } else {
        this.eventListeners.delete(eventName);
      }
    }
  }

  /**
   * See: https://v2.vuejs.org/v2/api/#vm-emit
   *
   * Trigger an event on the current instance. Any additional arguments will be passed into the listener’s callback function.
   *
   */
  $emit(eventName: string, ...args: any[]) {
    if (!this.eventListeners.has(eventName)) {
      return;
    }

    const eventListeners = this.eventListeners.get(eventName);
    if (!eventListeners) return;

    const eventListenerIndexesToDelete = [];
    for (const [
      eventListenerIndex,
      eventListener,
    ] of eventListeners.entries()) {
      eventListener.callback(...args);

      if (eventListener.once) {
        eventListenerIndexesToDelete.push(eventListenerIndex);
      }
    }

    for (let i = eventListenerIndexesToDelete.length - 1; i >= 0; i--) {
      eventListeners.splice(eventListenerIndexesToDelete[i], 1);
    }
  }
}

export const GlobalBus = new EventBus();
export default EventBus;
