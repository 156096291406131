<template>
  <v-navigation-drawer
    app
    permanent
    rail-width="48"
    width="350"
    expand-on-hover
    rail
    @update:rail="isCollapsed = $event"
  >
    <div class="-mb-2 mt-3 flex">
      <v-img
        :src="logoUrl"
        class="left-2 h-10 min-w-[32px] max-w-[32px]"
      />
      <v-img
        :src="titleUrl"
        class="left-7 h-10 min-w-[200px] max-w-[200px]"
      />
    </div>

    <div class="env-meta mb-2 text-gray-500">
      {{ envMeta.client }}
    </div>

    <v-list
      v-model:opened="openedGroup"
      density="compact"
      open-strategy="single"
    >
      <NavDrawerItem
        to="/"
        title="Overview"
        :icon="mdiHome"
      />

      <hr class="my-1" />

      <!-- ANALYSIS -->
      <NavDrawerGroup
        title="Financing Breakdown"
        :icon="mdiChartLine"
      >
        <NavDrawerItem
          to="/financing-breakdown/all-positions"
          title="All Positions"
        />
        <NavDrawerItem
          to="/financing-breakdown/shorts-borrows"
          title="Shorts & Borrows"
        />
        <NavDrawerItem
          to="/financing-breakdown/cash-collateral"
          title="Cash & Collateral"
        />
        <NavDrawerItem
          to="/financing-breakdown/swaps"
          title="Swaps"
        />
        <NavDrawerItem
          to="/financing-breakdown/portfolio-add-on"
          title="Portfolio Add-on"
        />
      </NavDrawerGroup>

      <NavDrawerGroup
        v-if="featureFlags.showTradeDateData"
        title="Trade Date Data"
        :icon="mdiCalendarStar"
        admin-only
        beta
      >
        <NavDrawerItem
          to="/trade-date-data/longs-shorts"
          title="Longs and Shorts"
        />
      </NavDrawerGroup>

      <NavDrawerGroup
        title="Analytical Tools"
        :icon="mdiImageFilterCenterFocus"
      >
        <NavDrawerItem
          title="Borrows"
          to="/analytical-tools/borrows"
        />
        <NavDrawerItem
          title="Single Security"
          to="/analytical-tools/single-security"
        />
      </NavDrawerGroup>

      <hr class="my-1" />

      <!-- OPTIMIZATIONS -->
      <NavDrawerGroup
        title="Optimizations"
        :icon="mdiSpeedometer"
      >
        <NavDrawerItem
          to="/optimizations/summary"
          title="Summary"
        />
        <NavDrawerItem
          to="/optimizations/actions"
          title="Actions"
          admin-only
        />
        <NavDrawerItem
          to="/optimizations/savings-analysis"
          title="Savings Analysis"
        />
      </NavDrawerGroup>

      <hr class="my-1" />

      <!-- MODULES -->
      <NavDrawerItem
        to="/billing-compare"
        title="Billing Compare"
        :icon="mdiCompare"
        subscription="billing_compare"
      />

      <NavDrawerGroup
        redirect="market-data"
        title="Securities Lending Data"
        :icon="mdiStickerText"
        subscription="stock_loan_data"
      >
        <NavDrawerItem
          to="/market-data/single-security"
          title="Single Security"
        />
        <NavDrawerItem
          to="/market-data/table"
          title="Table"
        />
      </NavDrawerGroup>

      <NavDrawerGroup
        title="Wallet"
        :icon="mdiWallet"
        redirect="wallet"
        subscription="wallet"
      >
        <NavDrawerItem
          to="/wallet/output"
          title="Output"
        />
        <NavDrawerItem
          to="/wallet/assumptions"
          title="Assumptions"
        />
        <NavDrawerItem
          to="/wallet/stage-summary"
          title="Stage Summary"
          admin-only
        />
      </NavDrawerGroup>
      <NavDrawerGroup
        title="Broker Analysis"
        :icon="mdiPresentation"
        subscription="broker_analysis"
        :hide-if-unsubscribed="true"
        beta
      >
        <NavDrawerItem
          to="/broker-review/broker-comparison"
          title="Broker Comparison"
          beta
        />
        <NavDrawerItem
          to="/broker-review/broker-focus"
          title="Broker Focus"
          beta
        />
      </NavDrawerGroup>

      <NavDrawerItem
        to="/strategies"
        title="Strategies"
        :icon="mdiDiversify"
        subscription="strategies"
      />

      <v-divider />

      <NavDrawerGroup
        title="Fee Schedule"
        :icon="mdiHandshakeOutline"
      >
        <NavDrawerItem
          to="/fee-schedule/overview"
          title="Fee Schedules"
        />
        <NavDrawerItem
          to="/fee-schedule/compare"
          title="Compare"
        />
      </NavDrawerGroup>

      <NavDrawerItem
        to="/unmapped-accounts"
        title="Unmapped Accounts"
        :icon="mdiAlarmLight"
        :admin-only="!envMeta.feature_flags.show_unmapped_accounts_page"
      />
    </v-list>

    <!-- APPEND -->
    <template #append>
      <v-list
        v-model:opened="openedGroup"
        density="compact"
        open-strategy="single"
      >
        <NavDrawerItem
          to="/admin-panel"
          title="Admin Panel"
          :icon="mdiAccountStar"
          admin-only
          external
        />

        <address
          class="env-meta my-4 text-primary"
          style="font-style: normal"
        >
          <span
            class="cursor-pointer"
            @click="copyEmailToClipboard"
          >
            support@kayenta.io
            <v-tooltip activator="parent">
              {{ emailCopied ? "Copied!" : "Click to copy email to clipboard" }}
            </v-tooltip>
          </span>
        </address>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import {
  mdiAccountStar,
  mdiAlarmLight,
  mdiCalendarStar,
  mdiChartLine,
  mdiCompare,
  mdiDiversify,
  mdiHandshakeOutline,
  mdiHome,
  mdiImageFilterCenterFocus,
  mdiPresentation,
  mdiSpeedometer,
  mdiStickerText,
  mdiWallet,
} from "@mdi/js";

import logoUrl from "@/assets/kayenta_favicon.svg";
import titleUrl from "@/assets/kayenta_title.svg";
import { useAppStore } from "@/store/app";

import { isCollapsedInjectionKey } from "./navDrawerInjectionKeys";

const { envMeta, featureFlags } = storeToRefs(useAppStore());

const openedGroup = ref();

const emailCopied = ref(false);

async function copyEmailToClipboard() {
  await navigator.clipboard.writeText("support@kayenta.io");

  emailCopied.value = true;
  setTimeout(() => (emailCopied.value = false), 2000);
}

const isCollapsed = ref(true);
provide(isCollapsedInjectionKey, isCollapsed);
</script>

<style scoped>
.env-meta {
  width: 330px;
  text-align: end;
  line-height: 0.875rem;
  font-size: 0.75rem;
}
</style>
