<template>
  <v-list-item
    :ripple="false"
    @click="modelValue = !modelValue"
  >
    <UiSwitch
      v-bind="$attrs"
      v-model="modelValue"
      density="compact"
      :ripple="false"
      :track-event
    >
      <template #label>
        <v-list-item-title>
          {{ label }}
        </v-list-item-title>
      </template>
    </UiSwitch>
  </v-list-item>
</template>

<script lang="ts" setup>
defineProps<{
  label: string;
  trackEvent: TrackEventProperties | undefined;
}>();

const modelValue = defineModel<boolean>({ required: true });
</script>

<style scoped>
:deep(.v-selection-control) {
  flex-direction: row-reverse;
  justify-content: space-between !important;
}
:deep(.v-selection-control__wrapper) {
  margin-right: 0.5rem;
}

:deep(.v-selection-control .v-label) {
  padding-left: 0;
}
</style>
