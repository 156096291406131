export type ToolTipText = keyof typeof tooltipLookup;

export const tooltipLookup = {
  not_available: {
    title: "Not Available",
    text: "Feature not implemented in Mockup",
  },
  "wallet_smv_internalisation_stock_loan_spread_(gc)": {
    title: "Borrow spread (GC)",
    text: "Borrow fees received from the Fund on stock borrows where the hedge is from the PB Client Book",
  },
  "wallet_smv_internalisation_financing_on_short_sale_proceeds_(gc)": {
    title: "Financing on Borrow Cash Collateral (GC)",
    text: "Financing paid to the Fund on the cash collateral held against borrows",
  },
  "wallet_smv_internalisation_financing_on_net_indebtedness_(gc)": {
    title: "Financing on Net Indebtedness (GC)",
    text: "Financing received from the PB Client Book on the net indebtedness funded by GC borrow cash collateral",
  },
  "wallet_smv_internalisation_interest_on_excess_collateral_(gc)": {
    title: "Interest on excess collateral (GC)",
    text: "Interest received at the Internal Cost of Funds rate on the excess collateral no longer needed for a market borrow trade",
  },
  "wallet_smv_internalisation_stock_loan_spread_(warm)": {
    title: "Borrow spread (Warm) ",
    text: "Borrow fees received from the Fund on stock borrows where the hedge is from the PB Client Book",
  },
  "wallet_smv_internalisation_financing_on_short_sale_proceeds_(warm)": {
    title: "Financing on Borrow Cash Collateral (Warm)",
    text: "Financing paid to the Fund on the cash collateral held against borrows",
  },
  "wallet_smv_internalisation_financing_on_net_indebtedness_(warm)": {
    title: "Financing on Net Indebtedness (Warm)",
    text: "Financing received from the PB Client Book on the net indebtedness funded by Warm borrow cash collateral. NB Hot names are assumed not to be sourced internally",
  },
  "wallet_smv_internalisation_interest_on_excess_collateral_(warm)": {
    title: "Interest on excess collateral (Warm)",
    text: "Interest received at the Internal Cost of Funds rate on the excess collateral no longer needed for a market borrow trade",
  },
  wallet_smv_internalisation_dividend_spread: {
    title: "Dividend spread",
    text: "Spread generated from the difference between the long position dividend receivable and short position dividend liability, using country level assumptions for yield. ",
  },
  wallet_smv_internalisation_offsetting_dividend_notional: {
    title: "Offsetting dividend notional",
    text: "Balancing entry",
  },
  wallet_smv_internalisation_offsetting_positions: {
    title: "Offsetting positions",
    text: "PB Client Book inventory being used to hedge borrows",
  },
  wallet_lmv_internalisation_financing_on_net_indebtedness: {
    title: "Financing on Net Indebtedness",
    text: "Financing received from the Fund on net indebtedness where LMV collateral is covering PB Client Book shorts",
  },
  "wallet_lmv_internalisation_stock_loan_spread_(gc)": {
    title: "Borrow spread (GC only) ",
    text: "Borrow fees received from the PB Client Book on stock borrows where the hedge is from the Fund's long positions",
  },
  wallet_lmv_internalisation_financing_on_short_sale_proceeds: {
    title: "Financing on Borrow Cash Collateral",
    text: "Financing paid to the PB Client Book on their borrow collateral requirement",
  },
  wallet_lmv_internalisation_interest_on_excess_collateral: {
    title: "Interest on excess collateral",
    text: "Interest received at the Internal Cost of Funds rate on the excess collateral no longer needed for a market borrow trade",
  },
  wallet_lmv_internalisation_dividend_spread: {
    title: "Dividend spread",
    text: "Spread generated from the difference between the long position dividend receivable and short position dividend liability, using country level assumptions for yield. ",
  },
  wallet_lmv_internalisation_offsetting_dividend_notional: {
    title: "Offsetting dividend notional",
    text: "Balancing entry",
  },
  wallet_lmv_internalisation_positions: {
    title: "Positions",
    text: "Fund long positions used to cover borrows on the PB Client Book",
  },
  "wallet_self_collateralisation_long_positions_used_as_collateral_(gc)": {
    title: "Long positions used as collateral (GC)",
    text: "Fund long positions used as non-cash collateral in hedge trades for Fund GC stock borrows ",
  },
  "wallet_self_collateralisation_stock_loan_spread_(gc)": {
    title: "Borrow spread (GC)",
    text: "Borrow fees received from the Fund on stock borrows where the hedge is traded in the market and and collateralised with the Fund's long positions",
  },
  "wallet_self_collateralisation_stock_loan_hedge_cost_(gc)": {
    title: "Borrow hedge cost (GC)",
    text: "Borrow fees paid to hedge borrow trades in the market",
  },
  "wallet_self_collateralisation_dividend_spread_(gc)": {
    title: "Dividend spread (GC)",
    text: "Spread generated from the difference between the dividend receivable from the Fund and the dividend payable on the hedge trade, using country level assumptions for yield and hedge entitlement. ",
  },
  "wallet_self_collateralisation_long_positions_used_as_collateral_(warm)": {
    title: "Long positions used as collateral (Warm)",
    text: "Fund long positions used as non-cash collateral in hedge trades for Fund Warm stock borrows ",
  },
  "wallet_self_collateralisation_stock_loan_spread_(warm)": {
    title: "Borrow spread (Warm) ",
    text: "Borrow fees received from the Fund on stock borrows where the hedge is traded in the market and and collateralised with the Fund's long positions",
  },
  "wallet_self_collateralisation_stock_loan_hedge_cost_(warm)": {
    title: "Borrow hedge cost (Warm)",
    text: "Borrow fees paid to hedge borrow trades in the market",
  },
  "wallet_self_collateralisation_dividend_spread_(warm)": {
    title: "Dividend spread (Warm)",
    text: "Spread generated from the difference between the dividend receivable from the Fund and the dividend payable on the hedge trade, using country level assumptions for yield and hedge entitlement. ",
  },
  "wallet_self_collateralisation_long_positions_used_as_collateral_(hot)": {
    title: "Long positions used as collateral (Hot)",
    text: "Fund long positions used as non-cash collateral in hedge trades for Fund Hot stock borrows ",
  },
  "wallet_self_collateralisation_stock_loan_spread_(hot)": {
    title: "Borrow spread (Hot)",
    text: "Borrow fees received from the Fund on stock borrows where the hedge is traded in the market and and collateralised with the Fund's long positions",
  },
  "wallet_self_collateralisation_stock_loan_hedge_cost_(hot)": {
    title: "Borrow hedge cost (Hot)",
    text: "Borrow fees paid to hedge borrow trades in the market",
  },
  wallet_self_collateralisation_financing_on_short_sale_proceeds: {
    title: "Financing on Borrow Cash Collateral",
    text: "Financing paid to the Fund on the borrow collateral requirement where the Fund's long positions are used as collateral",
  },
  wallet_self_collateralisation_financing_on_net_indebtedness: {
    title: "Financing on Net Indebtedness",
    text: "Financing received from the Fund on net indebtedness funded by released borrow cash collateral where LMV is used as stock borrow collateral instead",
  },
  wallet_self_collateralisation_interest_on_excess_collateral: {
    title: "Interest on excess collateral",
    text: "Interest received at the Internal Cost of Funds rate on the excess collateral no longer needed for a market borrow trade",
  },
  wallet_self_collateralisation_long_positions: {
    title: "Long positions",
    text: "Fund long positions used as non-cash collateral in hedge trades for Fund stock borrows",
  },
  wallet_self_collateralisation_offsetting_dividend_notional: {
    title: "Offsetting dividend notional",
    text: "Balancing entry",
  },
  "wallet_cash_collateral_stock_loan_spread_(gc)": {
    title: "Borrow spread (GC)",
    text: "Borrow fees received from the Fund on stock borrows where the hedge is traded in the market and and collateralised with cash",
  },
  "wallet_cash_collateral_stock_loan_hedge_cost_(gc)": {
    title: "Borrow hedge cost (GC)",
    text: "Borrow fees paid to hedge borrow trades in the market</div>",
  },
  "wallet_cash_collateral_interest_on_hedge_collateral_(gc)": {
    title: "Interest on hedge collateral (GC)",
    text: "Interest received from the counterparty on cash collateral posted against borrow hedge trades",
  },
  "wallet_cash_collateral_dividend_spread_(gc)": {
    title: "Dividend spread (GC)",
    text: "Spread generated from the difference between the dividend receivable from the Fund and the dividend payable on the hedge trade, using country level assumptions for yield and hedge entitlement. ",
  },
  "wallet_cash_collateral_stock_loan_spread_(warm)": {
    title: "Borrow spread (Warm) ",
    text: "Borrow fees received from the Fund on stock borrows where the hedge is traded in the market and and collateralised with cash",
  },
  "wallet_cash_collateral_stock_loan_hedge_cost_(warm)": {
    title: "Borrow hedge cost (Warm)",
    text: "Borrow fees paid to hedge borrow trades in the market",
  },
  "wallet_cash_collateral_interest_on_hedge_collateral_(warm)": {
    title: "Interest on hedge collateral (Warm)",
    text: "Interest received from the counterparty on cash collateral posted against borrow hedge trades",
  },
  "wallet_cash_collateral_dividend_spread_(warm)": {
    title: "Dividend spread (Warm)",
    text: "Spread generated from the difference between the dividend receivable from the Fund and the dividend payable on the hedge trade, using country level assumptions for yield and hedge entitlement. ",
  },
  "wallet_cash_collateral_stock_loan_spread_(hot)": {
    title: "Borrow spread (Hot)",
    text: "Borrow fees received from the Fund on stock borrows where the hedge is traded in the market and and collateralised with cash",
  },
  "wallet_cash_collateral_stock_loan_hedge_cost_(hot)": {
    title: "Borrow hedge cost (Hot)",
    text: "Borrow fees paid to hedge borrow trades in the market",
  },
  "wallet_cash_collateral_interest_on_hedge_collateral_(hot)": {
    title: "Interest on hedge collateral (Hot)",
    text: "Interest received from the counterparty on cash collateral posted against borrow hedge trades",
  },
  wallet_cash_collateral_financing_on_short_sale_proceeds: {
    title: "Financing on Borrow Cash Collateral",
    text: "Financing paid to the Fund on cash posted as borrow collateral",
  },
  wallet_cash_collateral_interest_on_overcollateral: {
    title: "Interest on over-collateral",
    text: "Interest received or paid on the difference between over-collateral taken from the Fund and over-collateral required for the stock borrow ",
  },
  wallet_cash_collateral_offsetting_dividend_notional: {
    title: "Offsetting dividend notional",
    text: "Balancing entry",
  },
  "wallet_overborrows_stock_loan_spread_(gc)": {
    title: "Borrow spread (GC)",
    text: "Borrow fees received from the Fund on overborrow trades",
  },
  "wallet_overborrows_stock_loan_hedge_cost_(gc)": {
    title: "Borrow hedge cost (GC)",
    text: "Borrow fees paid to hedge borrow trades in the market",
  },
  "wallet_overborrows_stock_loan_spread_(warm)": {
    title: "Borrow spread (Warm) ",
    text: "Borrow fees received from the Fund on overborrow trades",
  },
  "wallet_overborrows_stock_loan_hedge_cost_(warm)": {
    title: "Borrow hedge cost (Warm)",
    text: "Borrow fees paid to hedge borrow trades in the market",
  },
  "wallet_overborrows_stock_loan_spread_(hot)": {
    title: "Borrow spread (Hot)",
    text: "Borrow fees received from the Fund on overborrow trades",
  },
  "wallet_overborrows_stock_loan_hedge_cost_(hot)": {
    title: "Borrow hedge cost (Hot)",
    text: "Borrow fees paid to hedge borrow trades in the market",
  },
  wallet_overborrows_lent_to_brokers: {
    title: "Overborrow lent to brokers",
    text: "PB hedge inventory on overborrow trades lent out to brokers in overnight trades",
  },
  "wallet_overborrows_lent_to_brokers_(receivable)": {
    title: "Overborrow lent to brokers (receivable)",
    text: "Balancing entry",
  },
  wallet_overborrows_long_positions_used_as_collateral: {
    title: "Long positions used as collateral",
    text: "Fund long positions used as non-cash collateral in hedge trades for overborrows",
  },
  "wallet_overborrows_long_positions_used_as_collateral_(receivable)": {
    title: "Long positions used as collateral (receivable)",
    text: "Balancing entry",
  },
  wallet_overborrows_interest_on_cash_collateral: {
    title: "Interest on cash collateral",
    text: "Interest paid at the Internal Cost of Funds rate on the cash collateral required for the hedge trade where inventory is not lent to brokers",
  },
  wallet_overborrows_interest_on_hedge_collateral: {
    title: "Interest on hedge collateral",
    text: "Interest received from the counterparty on cash collateral posted against borrow hedge trades",
  },
  wallet_residual_remaining_lmv_being_rehypothecated: {
    title: "Remaining LMV being rehypothecated",
    text: "Remaining LMV that can be rehypothicated for use elsewhere",
  },
  wallet_residual_remaining_lmv_being_monetised: {
    title: "Remaining LMV being monetised",
    text: "Financing received from elsewhere on the remaining LMV being rehypothicated",
  },
  wallet_residual_remaining_lmv_outside_rehypothecation_limits: {
    title: "Remaining LMV outside rehypothecation limits",
    text: "Remaining LMV that can't be used as the rehypothication limit has been reached",
  },
  wallet_residual_financing_on_net_indebtedness: {
    title: "Financing on Net Indebtedness",
    text: "Financing received from the Fund on any remaining net indebtedness ",
  },
  wallet_residual_interest_on_hedge_financing: {
    title: "Interest on hedge financing",
    text: "Interest paid at the Internal Cost of Funds rate on cash required to fund the remaining net indebtedness",
  },
  wallet_residual_financing_on_cash_credit: {
    title: "Financing on Cash Credit ",
    text: "Financing paid to the Fund on cash credit balalnces",
  },
  wallet_residual_interest_on_cash_credit: {
    title: "Interest on Cash Credit ",
    text: "Interest received on the cash credit balance",
  },
  wallet_term_financing_term_funding_spread: {
    title: "Term funding spread",
    text: "Interest paid to hedge term financing on net indebtedness balance",
  },
  wallet_term_financing_term_funding: {
    title: "Term funding",
    text: "Balancing entry",
  },
  wallet_residual_term_funding_spread: {
    title: "Term funding spread",
    text: "Interest paid to hedge term financing on net indebtedness balance",
  },
  wallet_residual_term_funding: {
    title: "Term funding",
    text: "Balancing entry",
  },
  wallet_detail: {
    title: "Detail",
    text: "Detail of business action generating the accrual",
  },
  wallet_category: {
    title: "Category",
    text: "Category of business action",
  },
  wallet_subcategory: {
    title: "Subcategory",
    text: "Subcategory of business action",
  },
  wallet_entity: {
    title: "Entity",
    text: "Entity generating the accrual from the business action",
  },
  wallet_notional: {
    title: "Notional",
    text: "Notional value used in the business action",
  },
  wallet_gross_balances: {
    title: "Gross Balances",
    text: "Absolute value of Portfolio balance being utilised",
  },
  wallet_benchmark: {
    title: "Benchmark",
    text: "Applied benchmark interest rate, as defined in Fee Schedule or Wallet Assumptions",
  },
  wallet_spread: {
    title: "Spread",
    text: "Applied spread, as defined in Fee Schedule or Wallet Assumptions",
  },
  wallet_attribution: {
    title: "Attribution",
    text: "Applied attribution, as defined in Wallet Assumptions",
  },
  wallet_benchmark_accruals: {
    title: "Benchmark Accruals",
    text: "Daily benchmark accrual, calculated as Notional x Benchmark x Attribution / 360 ",
  },
  wallet_spread_accruals: {
    title: "Spread Accruals",
    text: "Daily spread accrual, calculated as Notional x Spread x Attribution / 360 ",
  },
  wallet_accruals: {
    title: "Accruals",
    text: "Daily accrual, calculated as Notional x (Benchmark +/- Spread) x Attribution / 360 ",
  },
  wallet_cumulative_accruals: {
    title: "Cumulative Accruals",
    text: "Aggregation of daily accruals across the defined date range",
  },
  wallet_cost_of_cash_spread: {
    title: "Cost Of Cash Spread",
    text: "PB cost of cash spread as defined in Wallet Assumptions",
  },
  wallet_gc_avg_spread: {
    title: "GC Average Spread",
    text: "Weighted average of GC borrow (USD value) spreads. Spreads are defined in the Fee Schedule and categorised as GC in Wallet Assumptions",
  },
  wallet_warm_avg_spread: {
    title: "Warm Average Spread",
    text: "Weighted average Warm borrow (USD value) spreads. Spreads are categorised as Warm in Wallet Assumptions, but ngotiated independently",
  },
  wallet_htb_avg_spread: {
    title: "HTB Average Spread",
    text: "Weighted average of Hot borrow (USD value) spreads. Spreads are categorised as Hot in Wallet Assumptions, but ngotiated independently",
  },
  wallet_ni_avg_spread: {
    title: "NI Average Spread",
    text: "Weighted average of Net Indebtedness (USD value) spreads",
  },
  wallet_ssp_avg_spread: {
    title: "BCC Average Spread",
    text: "Weighted average of Borrow Cash Collateral (USD value) spreads",
  },
  wallet_cc_avg_spread: {
    title: "CC Average Spread",
    text: "Weighted average of Cash Credits (USD value) spreads",
  },
  wallet_pb_cost_warm_avg_spread: {
    title: "PB Cost Warm Average Spread",
    text: "Weighted average of Warm borrow (USD value) spreads, reduced by a % margin defined in Wallet Assumptions to determine likely spread taken versus the hedge trade",
  },
  wallet_pb_cost_HTB_avg_spread: {
    title: "PB Cost HTB Average Spread",
    text: "Weighted average of Hot borrow (USD value) spreads, reduced by a % margin defined in Wallet Assumptions to determine likely spread taken versus the hedge trade",
  },
  wallet_smv_int_avg_div_yield: {
    title: "SMV Internalisation Average Dividend Yield",
    text: "Weighted average of dividend yields by market value (USD) of Portfolio short positions used in SMV Internalisation",
  },
  wallet_lmv_int_avg_div_yield: {
    title: "LMV Internalisation Average Dividend Yield",
    text: "Weighted average of dividend yields by market value (USD) of Portfolio long positions used in LMV Internalisation",
  },
  wallet_self_collat_avg_cost_of_gc: {
    title: "Self Collateral Average Cost Of GC",
    text: "Weighted average of GC borrow cost by market value (USD) for all short positions used in Self Collateralisation",
  },
  wallet_self_collat_avg_div_spread_smv_gc: {
    title: "Self Collateral Average Dividend Spread SMV GC",
    text: "Weighted average of the dividend spread by market taken on GC short positions collateralised through Self Collateralisation",
  },
  wallet_self_collat_avg_div_spread_smv_warm: {
    title: "Self Collateral Average Dividend Spread SMV Warm",
    text: "Weighted average of the dividend spread by market taken on Warm short positions collateralised through Self Collateralisation",
  },
  wallet_cash_collat_avg_cost_of_gc: {
    title: "Cash Collateral Average Cost Of GC",
    text: "Weighted average borrow cost by market value (USD) for GC text positions collateralised with Cash Collateral",
  },
  wallet_cash_collat_avg_div_spread_smv_gc: {
    title: "Cash Collateral Average Dividend Spread SMV GC",
    text: "Weighted average of the dividend spread by market taken on GC text positions collateralised with Cash Collateral",
  },
  wallet_cash_collat_avg_div_spread_smv_warm: {
    title: "Cash Collateral Average Dividend Spread SMV Warm",
    text: "Weighted average of the dividend spread by market taken on Warm text positions collateralised with Cash Collateral",
  },
  wallet_term_funding_spread: {
    title: "Term Funding Spread",
    text: "Spread between market funding rate on the agreed Term duration as defined in the Fee Schedule and the Prime Broker WAM (Weighted Average Maturity) rate",
  },
  market_data_on_loan_1: {
    title: "On Loan",
    text: "Quantity/Value of the security on loan calculated based on the utilization % and the total loan inventory",
  },
  market_data_on_loan_2: {
    title: "On Loan",
    text: "Quantity/Value of the security on loan as reported to FIS",
  },
  market_data_average_age_days: {
    title: "Average Age (days)",
    text: "Number of calendar days that have passed since the loan was opened",
  },
  market_data_demand_indicator: {
    title: "BAR",
    text: "Calculated by FIS, a rating on a scale of 1-10 that provides a simple indication of the demand level to borrow a stock, with 1 indicating a relatively low demand and 10 a relatively high demand.",
  },
  market_data_pricing: {
    title: "Pricing",
    text: "All borrow fees are quoted as intrinsic rates i.e. excluding any return on cash collateral or any costs to fund the collateral requirement.",
  },
  market_data_new_loans: {
    title: "New Loans",
    text: "New loans are loans opened on the selected date or additional units added to existing loans on the selected date.",
  },
  market_data_pb_average_stock_loan_rate: {
    title: "PB Average Borrow Rate",
    text: "Average borrow rate across all loans in a security from a given PB, weighted by size.",
  },
  market_data_spread_difference: {
    title: "Spread Difference",
    text: "FIS Average Rate (All Loans) - PB Borrow Rate.",
  },
  market_data_daily_charge_difference: {
    title: "Daily Charge Difference (USD)",
    text: "Spread Difference x Market Value (USD) / 360",
  },
  market_data_monthly_charge_difference: {
    title: "Monthly Charge Difference (USD)",
    text: "30.5 x Daily Charge Difference (USD)",
  },
  market_data_potential_monthly_lending_value: {
    title: "Potential Monthly Lending Value",
    text: "30.5 x (FIS Average Rate x Market Value USD) / 360",
  },
  market_data_rate_change_absolute: {
    title: "Rate Change – Absolute",
    text: "FIS average market rate at end of selected period - FIS average market rate at beginning of selected period",
  },
  market_data_rate_change_relative: {
    title: "Rate Change – Relative",
    text: "(FIS average market rate at end of selected period - FIS average market rate at beginning of selected period) / FIS average market rate at beginning of selected period x 100",
  },
  market_data_rate_change_impact: {
    title: "Market Rate Change Impact ",
    text: "Rate Change Absolute % over selected period x Total Market Value on Loan (USD)",
  },
  market_data_percent_shares_lent: {
    title: "% of Shares Lent",
    text: "(Market value of client loan portfolio/Total market value on loan) x 100",
  },
  market_data_remaining_availability: {
    title: "% of Remaining Availability",
    text: "(Market value of client loan portfolio/Remaining available market value to borrow) x 100",
  },
  change_day: {
    title: "Change Day",
    text: "Change in value between the selected date and yesterday",
  },
  change_week: {
    title: "Change Week",
    text: "Change in value between the selected date and 7 days ago",
  },
  change_month: {
    title: "Change Month",
    text: "Change in value between the selected date and 30 days ago",
  },
  change_quarter: {
    title: "Change Quarter",
    text: "Change in value between the selected date and 90 days ago",
  },
  change_year: {
    title: "Change Year",
    text: "Change in value between the selected date and 365 days ago",
  },
  htb_vs_total_borrow: {
    title: "HTB vs Total Borrow",
    text: "% of short book that is HTB (swaps calculated from strike notionals)<br/>Where physical longs or physical shorts are referenced, it is only using the eligible physical longs or eligible physical shorts<br/><i>Formula: (HTB B - HTB SSSN) / (B - SSSN)</i>",
  },
  htb_vs_total_borrow_physical: {
    title: "HTB vs Total Borrow (Physical)",
    text: "% of short physical book that is HTB<br/>Where physical longs or physical shorts are referenced, it is only using the eligible physical longs or eligible physical shorts<br/><i>Formula: (HTB B) / B</i>",
  },
  htb_vs_total_borrow_swaps: {
    title: "HTB vs Total Borrow (Swaps)",
    text: "% of short swap book that is HTB (calculated from strike notionals)<br/><i>Formula: (HTB SSSN) / SSSN</i>",
  },
  shorts_vs_gross_security: {
    title: "Short vs Gross Security",
    text: "% of total book that is short (swaps calculated from underlying notionals)<br/>Where physical longs or physical shorts are referenced, it is only using the eligible physical longs or eligible physical shorts<br/><i>Formula: (SMV + SSUN) / (-LMV + SMV - LSUN + SSUN)</i>",
  },
  shorts_vs_gross_security_physical: {
    title: "Short vs Gross Security (Physical)",
    text: "% of physical book that is short<br/>Where physical longs or physical shorts are referenced, it is only using the eligible physical longs or eligible physical shorts<br/><i>Formula: SMV / (-LMV + SMV)</i>",
  },
  shorts_vs_gross_security_swaps: {
    title: "Short vs Gross Security (Swaps)",
    text: "% of swap book that is short (calculated from underlying notionals)<br/><i>Formula: SSUN / (-LSUN + SSUN)</i>",
  },
  short_proceeds_vs_leverage: {
    title: "Short Collateral vs Leverage",
    text: "% total short collateral as a percentage of total leverage and Borrow Cash Collateral (calculated from Borrow Cash Collateral, net indebtedness and swap underlying notionals)<br/><i>Formula: (-BCC + SSUN) / (NI - LSUN -BCC + SSUN)</i>",
  },
  short_proceeds_vs_leverage_physical: {
    title: "Short Collateral vs Leverage (Physical)",
    text: "% Borrow Cash Collateral as a percentage of Net Indebtedness and Borrow Cash Collateral (physical balances only)<br/><i>Formula: -BCC / (NI - BCC)</i>",
  },
  short_proceeds_vs_leverage_swaps: {
    title: "Short Collateral vs Leverage (Swaps)",
    text: "% swap shorts as a percentage of swap longs and swap shorts (calculated from underlying notionals)<br/><i>Formula: SSUN / (-LSUN + SSUN)</i>",
  },
  physical_shorts_vs_physical_indebtedness: {
    title: "Physical Shorts vs Physical Indebtedness",
    text: "% physical short skew<br/>Where physical longs or physical shorts are referenced, it is only using the eligible physical longs or eligible physical shorts<br/><i>Formula: SMV / SMV + NI</i>",
  },
  physical_indebtedness_vs_physical_shorts: {
    title: "Physical Indebtedness vs Physical Shorts",
    text: "% physical long skew<br/>Where physical longs or physical shorts are referenced, it is only using the eligible physical longs or eligible physical shorts<br/><i>Formula: NI / SMV + NI</i>",
  },
  weighted_average_rates_cash_credit: {
    title: "Cash Credit",
    text: "Weighted average rate applied to Cash Credit balances",
  },
  weighted_average_rates_net_indebtedness: {
    title: "Net Indebtedness",
    text: "Weighted average rate applied to Net Indebtedness balances",
  },
  weighted_average_rates_stock_loan: {
    title: "Borrow",
    text: "Weighted average rate applied to Borrow balances",
  },
  weighted_average_rates_gc: {
    title: "Borrow GC",
    text: "Weighted average rate applied to GC Borrow balances",
  },
  weighted_average_rates_htb: {
    title: "Borrow HTB",
    text: "Weighted average rate applied to HTB Borrow balances",
  },
  weighted_average_rates_short_sale_proceeds: {
    title: "Borrow Cash Collateral",
    text: "Weighted average rate applied to Borrow Cash Collateral balances",
  },
  weighted_average_rates_long_swaps: {
    title: "Long Swaps",
    text: "Weighted average rate applied to Long Swap Strike notionals",
  },
  weighted_average_rates_short_swaps: {
    title: "Short Swaps",
    text: "Weighted average rate applied to Short Swap Strike notionals",
  },
  overview_balances_gross_total: {
    title: "Gross",
    text: "Total gross balance across physical and swap accounts, only including eligible securities within the financing arrangement and cash credit (i.e. excluding ineligible securities such as options)<br/><i>Formula: LMV - SMV + LSUN - SSUN + CC + SCC</i>",
  },
  overview_balances_physical_lmv: {
    title: "Physical LMV",
    text: "Market value of physical long eligible securities<br/><i>Formula: LMV</i>",
  },
  overview_balances_physical_smv: {
    title: "Physical SMV",
    text: "Market value of physical short positions<br/><i>Formula: SMV</i>",
  },
  overview_balances_long_swaps: {
    title: "Long Swaps",
    text: "Underlying value of long swaps<br/><i>Formula: LSUN</i>",
  },
  overview_balances_short_swaps: {
    title: "Short Swaps",
    text: "Underlying value of short swaps<br/><i>Formula: SSUN</i>",
  },
  overview_balances_gross_cash_credit: {
    title: "Gross: Cash Credit",
    text: "Cash Credit balance<br/><i>Formula: CC</i>",
  },
  overview_balances_net_total: {
    title: "Net",
    text: "Total net balance across physical and swap accounts, only including eligible securities within the financing arrangement and cash credit (i.e. excluding ineligible securities such as options)<br/><i>Formula: LMV + SMV + LSUN + SSUN + CC + SCC</i>",
  },
  overview_balances_net_physical: {
    title: "Net: Physical",
    text: "Net balance across physical exposures<br/><i>Formula: LMV + SMV</i>",
  },
  overview_balances_net_lmv: {
    title: "Net: Physical LMV",
    text: "Long physical balances<br/><i>Formula: LMV</i>",
  },
  overview_balances_net_smv: {
    title: "Net: Physical SMV",
    text: "Short physical balances<br/><i>Formula: SMV</i>",
  },
  overview_balances_net_swaps: {
    title: "Net: Swaps",
    text: "Net balance across swap underlying security exposures<br/><i>Formula: LSUN + SSUN</i>",
  },
  overview_balances_net_longs: {
    title: "Net: Swaps Longs",
    text: "Long swap underlying balances<br/><i>Formula: LSUN</i>",
  },
  overview_balances_net_shorts: {
    title: "Net: Swaps Shorts",
    text: "Short swap underlying balances<br/><i>Formula: SSUN</i>",
  },
  overview_balances_aggregate_longs: {
    title: "Aggregate: Longs",
    text: "Total long balances across elligible physical securities and long swap underlying security notional<br/><i>Formula: LMV + LSUN</i>",
  },
  overview_balances_aggregate_physical_lmv: {
    title: "Aggregate: Physical LMV",
    text: "Long physical eligible security balances<br/><i>Formula: LMV</i>",
  },
  overview_balances_aggregate_long_swaps: {
    title: "Aggregate: Long Swaps",
    text: "Long swap underlying security notional<br/><i>Formula: LSUN</i>",
  },
  overview_balances_aggregate_shorts: {
    title: "Aggregate: Shorts",
    text: "Total short balances across physical and short swap underlying security notional<br/><i>Formula: SMV + SSUN</i>",
  },
  overview_balances_aggregate_physical_smv: {
    title: "Aggregate: Physical SMV",
    text: "Short physical balances<br/><i>Formula: SMV</i>",
  },
  overview_balances_aggregate_short_swaps: {
    title: "Aggregate: Short Swaps",
    text: "Short swap underlying security notional<br/><i>Formula: SSUN</i>",
  },
  overview_balances_aggregate_leverage: {
    title: "Aggregate: Leverage",
    text: "Total leveraged longs from Net Indebtedness in physical accounts and Long Swap Strike Notional in swap accounts",
  },
  overview_balances_aggregate_physical: {
    title: "Aggregate: Cash Leverage Physical",
    text: "Physical Cash Leverage is the Net Indebtedness balance, including Unsecured<br/><i>Formula: NI</i>",
  },
  overview_balances_aggregate_swaps: {
    title: "Aggregate: Cash Leverage Swaps",
    text: "Swap Cash Leverage is the Long Swap Strike Notional<br/><i>Formula: -LSSN</i>",
  },
  overview_balances_gross_funding_total: {
    title: "Gross Funding",
    text: "Total absolute funding balance generating accruals. Balances are Net Indebtedness, physical account Cash Credit, Borrows, Long Swaps and Short Swaps. Borrow Cash Collateral balances are excluded to avoid duplication of Borrow balances.<br/><i>Formula: CC - NI + B + LSSN - SSSN</i>",
  },
  overview_balances_gross_funding_cash_credit: {
    title: "Gross Funding: Cash Credit",
    text: "Cash Credit balance in physical account<br/><i>Formula: CC</i>",
  },
  overview_balances_gross_funding_net_indebtedness: {
    title: "Gross Funding: Net Indebtedness",
    text: "Absolute Net Indebtedness balance<br/><i>Formula: -NI</i>",
  },
  overview_balances_gross_funding_stock_loan: {
    title: "Gross Funding: Borrow",
    text: "Borrow balance (including GC and HTB)<br/><i>Formula: B</i>",
  },
  overview_balances_gross_funding_gc: {
    title: "Gross Funding: GC",
    text: "GC Borrow balance<br/><i>Formula: B(GC)</i>",
  },
  overview_balances_gross_funding_htb: {
    title: "Gross Funding: HTB",
    text: "Hard To Borrow (HTB) Borrow balance<br/><i>Formula: B(HTB)</i>",
  },
  overview_balances_gross_funding_swaps: {
    title: "Gross Funding: Swaps",
    text: "Gross balance of Long and Short swaps using Strike Notionals<br/><i>Formula: LSSN - SSSN</i>",
  },
  overview_balances_gross_funding_longs: {
    title: "Gross Funding: Longs",
    text: "Long Swap Strike Notional<br/><i>Formula: LSSN</i>",
  },
  overview_balances_gross_funding_shorts: {
    title: "Gross Funding: Shorts",
    text: "Short Swap Strike Notional<br/><i>Formula: -SSSN</i>",
  },
  overview_balances_excess_fundable_total: {
    title: "Excess Fundable",
    text: "Total fundable assets not being utilised as collateral<br/><i>Formula: CC Excess + LMV Excess</i>",
  },
  overview_balances_excess_fundable_cash: {
    title: "Excess Fundable: Cash",
    text: "Excess Cash Credit i.e. Cash Credit not being utilized as collateral<br/><i>Formula: CC Excess</i>",
  },
  overview_balances_excess_fundable_securities: {
    title: "Excess Fundable: Securities",
    text: "Excess eligible LMV i.e. Eligible LMV not being utilized as collateral<br/><i>Formula: LMV Excess</i>",
  },
  overview_balances_htb_total: {
    title: "HTB",
    text: "Hard to Borrow value across Shorts and Short Swaps (Underlying Value) by Spread bucket",
  },
  overview_accruals_net_indebtedness: {
    title: "Net Indebtedness",
    text: "Includes both Secured and Unsecured Net Indebtedness accruals",
  },
  overview_accruals_short_sale_proceeds: {
    title: "Borrow Cash Collateral",
    text: "Includes Benchmark and Borrow Cash Collateral Spread accruals where relevant",
  },
  rate_type_total: {
    title: "Total",
    text: "Total of Spread and Benchmark accrual rates",
  },
  rate_type_spread: {
    title: "Spread",
    text: "Accrual from just the Spread rate, excluding all Benchmarks",
  },
  rate_type_benchmark: {
    title: "Benchmark",
    text: "Accrual from just the Benchmark rates, excluding all Spreads",
  },
  position_analysis_graph_key_notional: {
    title: "Notional",
    text: "Market Value for Cash positions and Notional Value for Swap positions",
  },
  broker_review_value_ytd_annualized_change_to_prior_year: {
    title: "Change",
    text: "Year to date annualized change to prior year",
  },
  broker_review_value_qtd_quarterized_change_to_prior_quarter: {
    title: "Change To Prior Quarter",
    text: "Quarter to date quarterized change to prior quarter",
  },
  broker_review_value_qtd_quarterized_change_to_prior_year: {
    title: "Change To Prior Year",
    text: "Quarter to date quarterized change to prior year",
  },
  broker_review_value_year_average_change_to_prior_year: {
    title: "Change To Prior Year",
    text: "Year average change to prior year",
  },
  broker_review_value_quarter_average_change_to_prior_quarter: {
    title: "Change To Prior Quarter",
    text: "Quarter average change to prior quarter",
  },
  broker_review_value_quarter_average_change_to_prior_year: {
    title: "Change To Prior Year",
    text: "Quarter average change to prior year",
  },
};
