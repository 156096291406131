<template>
  <UiIconBtn
    aria-label="Restore page settings"
    tooltip-text="Restore page settings"
    size="40"
    :track-event="{ name: 'Restore Page Settings' }"
    @click="handleResetViewClick"
  >
    {{ mdiRestore }}
  </UiIconBtn>

  <v-menu
    :key="key"
    v-model="isOpen"
    offset="10"
    transition="slide-y-transition"
    :close-on-content-click="false"
  >
    <template #activator="{ props: activatorProps }">
      <UiIconBtn
        v-bind="activatorProps"
        aria-label="App Settings"
        size="40"
        :track-event="{ name: 'App Settings' }"
      >
        {{ mdiAccountCog }}
      </UiIconBtn>
    </template>
    <AppSettingsCard data-testid="app-settings-card" />
  </v-menu>
</template>

<script setup lang="ts">
import { mdiAccountCog, mdiRestore } from "@mdi/js";

import { useAppStore } from "@/store/app";

const isOpen = ref(false);
const key = ref(0);
watch(isOpen, (isOpen) => isOpen && key.value++);

const { globalFilters } = storeToRefs(useAppStore());

const trackEvent = useTrackEvent();

function handleResetViewClick() {
  GlobalBus.$emit("reset-view");

  globalFilters.value = {
    prime_broker: [],
    fund: [],
    account: [],
    currency: [],
  };

  trackEvent.trigger({
    name: "Restore Page Settings",
  });
}
</script>
