import dayjs from "dayjs";

import { useAppStore } from "~/store/app";
import { getBreakpoint } from "~/utils/getBreakpoint";

// Numbered as 99 so that it is last to run after any possible redirects
export default defineNuxtRouteMiddleware((to, from) => {
  if (import.meta.server) return;

  const appStore = useAppStore();
  const { path, hash, query, params, name } = to;
  const pageId = name ? String(name) : path;

  const properties = {
    pageId,
    pageAttributes: {
      path,
      hash,
      query: JSON.stringify(query),
      params: JSON.stringify(params),
      refresh: from.path === to.path,
      from: from.path,
      local_time: dayjs().format("HH:mm:ss"),
      screen_size: `${window.innerWidth}x${window.innerHeight}`,
      breakpoint: getBreakpoint(window.innerWidth),
    },
  };

  if (appStore.logTrackEventsToAws) {
    const { awsRum } = useAwsRum();
    awsRum?.recordPageView(properties);
  }

  if (appStore.logTrackEventsToConsole) {
    console.info(`Track Event: navigation`, properties);
  }
});
