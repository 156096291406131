import type { Directive } from "vue";

import { tooltipLookup, type ToolTipText } from "~/assets/tooltipLookup";

export const vTooltip: Directive = {
  created(el, binding) {
    if (binding.value) {
      attachMouseListeners(el, binding.value);
    }
  },
  updated(el, binding) {
    if (binding.value) {
      attachMouseListeners(el, binding.value);
    }
  },
  unmounted() {
    GlobalBus.$emit("snackbar:tooltip", null);
  },
};

function attachMouseListeners(el: HTMLElement, tooltipId: ToolTipText) {
  el.onmouseenter = () => {
    el.classList.add("tooltip__hovered");

    const tooltip = tooltipLookup[tooltipId];

    if (tooltip) {
      const { title, text } = tooltip;

      const content = `
      <div class="mb-4 font-bold">
      ${title}
      </div>
      <div>
      ${text}
      </div>
      `;

      GlobalBus.$emit("snackbar:tooltip", content);
    }
  };
  el.onmouseleave = () => {
    el.classList.remove("tooltip__hovered");
    GlobalBus.$emit("snackbar:tooltip", null);
  };
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive("tooltip-text", vTooltip);
});
