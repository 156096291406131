export class UiIcon extends HTMLElement {
  constructor() {
    super();

    const shadowRoot = this.attachShadow({ mode: "open" });

    shadowRoot.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path fill="${theme.neutral[500]}">
            </path>
        </svg>
        <style>
        :host {
            --size: 24px;
            display: inline-block;
            width: var(--size);
            height: var(--size);
        }
        </style>
    `;
  }

  static get observedAttributes() {
    return ["icon", "color", "size"];
  }

  attributeChangedCallback(name: string, _oldValue: string, newValue: string) {
    if (name === "icon") {
      const path = this.shadowRoot?.querySelector("path");

      path?.setAttribute("d", newValue);
    }
    if (name === "color") {
      const path = this.shadowRoot?.querySelector("path");

      path?.setAttribute("fill", newValue);
    }
    if (name === "size") {
      const svg = this.shadowRoot?.querySelector("svg");

      svg?.setAttribute("width", newValue);
      svg?.setAttribute("height", newValue);

      const style = this.shadowRoot?.querySelector("style");

      if (style) {
        style.innerText = style.innerText.replace(
          /(?<=--size: ).*?(?=;)/,
          `${newValue}px`
        );
      }
    }
  }
}
