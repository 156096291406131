const simpleWordBreaks = "[_-\\s]";
const capitalNotFollowedByLower = "[A-Z]+(?![a-z])";
const alphaCharFollowedByLowerOrNone = "[a-zA-Z][a-z]*";

const wordSplit = RegExp(
  [
    `(${simpleWordBreaks})`,
    `(${capitalNotFollowedByLower})`,
    `(${alphaCharFollowedByLowerOrNone})`,
  ].join("|")
);
/**
 * Splits any string into an array of words
 * @param str
 */
const allToArr = (str: string): string[] =>
  str
    .split(wordSplit)
    .filter((e) => !["-", "_", " ", "", undefined].includes(e))
    .map((e) => e.toLowerCase());

if (import.meta.vitest) {
  const { describe, it, expect } = import.meta.vitest;
  describe("allToArr", () => {
    it("should convert spaced to lowercase array", () => {
      expect(allToArr("This is a test")).toEqual(["this", "is", "a", "test"]);
    });
    it("should convert snake to lowercase array", () => {
      expect(allToArr("this_is_a_test")).toEqual(["this", "is", "a", "test"]);
    });
    it("should convert kebab to lowercase array", () => {
      expect(allToArr("this-is-a-test")).toEqual(["this", "is", "a", "test"]);
    });
    it("should convert snake to lowercase array", () => {
      expect(allToArr("thisIsATest")).toEqual(["this", "is", "a", "test"]);
    });
    it("should convert all caps to lowercase array", () => {
      expect(allToArr("THIS IS A TEST")).toEqual(["this", "is", "a", "test"]);
    });
  });
}

/**
 * Converts an array of lowercase strings to a string in title case
 * @param arr
 */
const arrToTitle = (arr: string[]): string =>
  arr
    .map((e) => {
      return e.charAt(0).toUpperCase() + e.slice(1).toLowerCase().trim();
    })
    .join(" ");

if (import.meta.vitest) {
  const { describe, it, expect } = import.meta.vitest;
  describe("arrToTitle", () => {
    it("should convert array to title", () => {
      expect(arrToTitle(["this", "is", "a", "test"])).toBe("This Is A Test");
    });
  });
}

/**
 * Converts an array of lowercase strings to a string in snake case
 * @param arr
 */
const arrToSnake = (arr: string[]): string => arr.join("_");
if (import.meta.vitest) {
  const { describe, it, expect } = import.meta.vitest;
  describe("arrToSnake", () => {
    it("should convert array to snake", () => {
      expect(arrToSnake(["this", "is", "a", "test"])).toBe("this_is_a_test");
    });
  });
}
/**
 * Converts an array of lowercase strings to a string in kebab case
 * @param arr
 */
const arrToKebab = (arr: string[]): string => arr.join("-");
if (import.meta.vitest) {
  const { describe, it, expect } = import.meta.vitest;
  describe("arrToKebab", () => {
    it("should convert array to kebab", () => {
      expect(arrToKebab(["this", "is", "a", "test"])).toBe("this-is-a-test");
    });
  });
}
/**
 * Converts an array of lowercase strings to a string in camel case
 * @param arr
 */
const arrToCamel = (arr: string[]): string =>
  arr
    .map(([first, ...rest], i) =>
      i === 0
        ? first.toLowerCase() + rest.join("")
        : first.toUpperCase() + rest.join("")
    )
    .join("");

if (import.meta.vitest) {
  const { describe, it, expect } = import.meta.vitest;
  describe("arrToCamel", () => {
    it("should convert array to camel", () => {
      expect(arrToCamel(["this", "is", "a", "test"])).toBe("thisIsATest");
    });
  });
}

/**
 * Converts a string to title case
 * @param str
 */
export const toTitle = (str: string): string => {
  const arr = allToArr(str);
  return arrToTitle(arr);
};

/**
 * Converts a string to snake case
 * @param str
 */
export const toSnake = (str: string): string => {
  const arr = allToArr(str);
  return arrToSnake(arr);
};

/**
 * Converts a string to kebab case
 * @param str
 */
export const toKebab = (str: string): string => {
  const arr = allToArr(str);
  return arrToKebab(arr);
};

/**
 * Converts a string to camel case
 * @param str
 */
export const toCamel = (str: string): string => {
  const arr = allToArr(str);
  return arrToCamel(arr);
};
