export function removeOverviewWeightedAverageRates(
  settings: Dictionary<any>,
  _section: string,
  storage: Storage
) {
  if (storage.getItem("settings:overview_war:toolbar")) {
    storage.removeItem("settings:overview_war:toolbar");
  }
  return settings;
}
