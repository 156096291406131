// import this after install `@mdi/font` package
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

import type { ThemeDefinition } from "vuetify";
import { createVuetify } from "vuetify";
import { md1 } from "vuetify/blueprints";
import { aliases, mdi } from "vuetify/iconsets/mdi-svg";

import { theme } from "../utils/colorPack";

const kayentaTheme: ThemeDefinition = {
  dark: false,
  colors: {
    primary: theme.primary,
    secondary: theme.secondary,
    accent: theme.accent,
    error: theme.red[500],
    info: theme.blue[500],
    success: theme.emerald[400],
    warning: theme.orange[500],
    background: theme.background,
    surface: theme.white,
  },
};

export default defineNuxtPlugin((app) => {
  const vuetify = createVuetify({
    icons: {
      defaultSet: "mdi",
      aliases,
      sets: {
        mdi,
      },
    },
    theme: {
      defaultTheme: "kayentaTheme",
      themes: {
        kayentaTheme,
      },
    },
    blueprint: md1,
    defaults: {
      global: {
        hideDetails: true,
        persistentPlaceholder: true,
      },
      VSwitch: {
        color: "primary",
      },
      VCheckbox: {
        color: "primary",
      },
      VBtn: {
        color: "transparent",
        variant: "flat",
        // will create class 'rounded-default' which has no styles, so uses btn default, which for icon is icon-button-radius
        // Otherwise would create class 'rounded-0'.
        rounded: "default",
      },
      VSelect: {
        transition: "fade-transition",
      },
      VSheet: {
        rounded: true,
      },
      VAutocomplete: {
        transition: "fade-transition",
      },
    },
  });
  app.vueApp.use(vuetify);
});
