<template>
  <v-btn
    v-bind="$attrs"
    icon
    :disabled
    :size
    @click="handleClick"
  >
    <slot name="tooltip">
      <v-tooltip
        v-if="tooltipText"
        v-bind="tooltipProps"
        :disabled
        activator="parent"
      >
        {{ tooltipText }}
      </v-tooltip>
    </slot>
    <v-icon
      :class="[
        iconVariant,
        'transition-transform',
        {
          [rotateClass]: props.rotate,
          '!opacity-50': props.disabled,
        },
      ]"
      :size="iconSize"
    >
      <slot />
    </v-icon>
  </v-btn>
</template>

<script lang="ts" setup>
import type { ComponentProps } from "vue-component-type-helpers";
import type { VTooltip } from "vuetify/components";

type TooltipProps = ComponentProps<typeof VTooltip>;
const props = withDefaults(
  defineProps<{
    trackEvent: TrackEventProperties;
    size?: string;
    disabled?: boolean;
    tooltipText?: string;
    tooltipProps?: TooltipProps;
    variant?: "positive" | "negative" | "neutral";
    iconSize?: string;
    rotate?: boolean;
    rotateClass?: string;
  }>(),
  {
    color: "primary",
    variant: "positive",
    rotateClass: "rotate-90",
    tooltipProps: () => ({ location: "top", openDelay: 500 }),
  }
);

const variantLookup = () => {
  switch (props.variant) {
    case "positive":
      return "text-accent";
    case "negative":
      return "text-red-400";
    case "neutral":
      return "text-grey-900";
  }
  props.variant satisfies never;
};

const iconVariant = computed(variantLookup);

const trackEventFn = useTrackEvent();
function handleClick() {
  // Use nextTick to ensure values are updated before tracking the event
  nextTick(() => {
    if (props.trackEvent) {
      trackEventFn.trigger({ element: "button", ...props.trackEvent });
    }
  });
}
</script>

<style></style>
