import { unique } from "@/utils/unique";

/**
 * A function that will insert or remove the given item from a given array
 * @param arr  The array to be changed
 * @param item  The item to insert or remove
 * @param [isInsert]  Optional. If not provided will add if it is not present in array and will remove if it is present. If provided, should the item be inserted (true), or removed (false)
 * @returns  A new array with the item added or removed
 */
export const insertOrRemoveItem = <T>(
  arr: T[],
  item: T,
  isInsert?: boolean
): T[] => {
  const doInsert = isInsert ?? !arr.includes(item);

  return doInsert ? insert(arr, item) : remove(arr, item);
};

function insert<T>(arr: T[], item: T): T[] {
  return unique([...arr, item]);
}

function remove<T>(arr: T[], item: T): T[] {
  return arr.filter((e) => e !== item);
}
