import type { InjectionKey } from "vue";
import { inject } from "vue";

import { createTableHelpers } from "./createTableHelpers";
import type { Options } from "./dataTableTypes";

export const dataTableOptionsKey: InjectionKey<Options<any>> =
  Symbol("datatable:options");

export function useTableOptions() {
  return inject(
    dataTableOptionsKey,
    () => {
      console.error("No options were provided. Using defaults instead.");

      return createTableHelpers().createOptions();
    },
    true
  );
}
