export function toFixedNumber(number: number, fractionDigits = 0) {
  const formatter = Intl.NumberFormat("en-US", {
    maximumFractionDigits: fractionDigits,
    useGrouping: false,
  });

  const toFixed = Number(formatter.format(number));
  // Removes instances of -0
  return isNaN(toFixed) ? NaN : toFixed + 0;
}
