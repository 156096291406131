import type { Account } from "@/models";
import { createDateRangeArray } from "@/utils/createDateRangeArray";
import { maxSafeDateMs, UTC } from "@/utils/UTC";
import { allBrokers } from "~/components/BrokerReview/Comparison/allBrokers";
import type { BrokerItem, ColumnType } from "~/components/BrokerReview/types";

import { useAppStore } from "./app";
import { type DateQuickSelectOption, useDate } from "./useDate";

export type UseDateBrokerReview = typeof useBrokerReview;

export const returnsTypeOptions = [
  "Wallet / Balance Sheet (RoA)",
  "Spread / Balance Sheet (RoA)",
  "Wallet / Gross Funding",
  "Spread / Gross Funding",
] as const;

export const revenueTypeOptions = ["Total", "Spread", "Wallet"] as const;

export const ratiosTypeOptions = [
  "HTB vs Total Borrow",
  "Shorts vs Gross Security",
  "Skew (Physical Indebtedness vs BCC)",
] as const;

export const balancesTypeOptions = [
  "Balance Sheet",
  "Gross Funding",
  "Aggregate Borrowed Securities",
  "Aggregate Leverage",
  "Net Underlying",
  "Gross Underlying",
] as const;

export const useBrokerReview = defineStore("brokerReview", () => {
  const { accounts, defaultDateRange } = storeToRefs(useAppStore());

  const yesterday = UTC().subtract(1, "day");

  const {
    startDate,
    endDate,
    selectedDate,
    cachedDateRange,
    setPeriod,
    getQuickSelectOptions,
  } = useDate();

  startDate.value = yesterday.format("YYYY-MM-DD");
  endDate.value = yesterday.format("YYYY-MM-DD");
  selectedDate.value = yesterday.format("YYYY-MM-DD");

  const inceptionDate = computed<string>(
    () =>
      accounts.value
        .map((e) => e.start_date)
        .filter(Boolean)
        .sort((a, b) => +UTC(a) - +UTC(b))
        .at(0) ?? yesterday.format("YYYY-MM-DD")
  );

  /*
   * Latest date any pipeline has data.
   *  - Defaults to yesterday if no data received
   *  - Cannot display todays date
   */

  function accountPipelineHasRunOnDate(date: string) {
    return (account: Account) =>
      // Account is active on date
      +UTC(account.end_date ?? maxSafeDateMs) >= +UTC(date) &&
      // Account has run pipeline
      !account.missing_dates.includes(date);
  }

  const latestDate = computed<string>(() => {
    const dateRange = createDateRangeArray(inceptionDate.value, yesterday);

    const latestAccountDate = dateRange.findLast((date) =>
      accounts.value.find(accountPipelineHasRunOnDate(date))
    );

    return latestAccountDate ?? endDate.value;
  });

  function setDatesToLatest(quickSelectOptions: DateQuickSelectOption[]) {
    const selectedOption =
      quickSelectOptions.find((e) => e.title === defaultDateRange.value) ??
      quickSelectOptions[0];

    const [defaultStart, defaultEnd] = selectedOption.value;

    const startDateMS = Math.max(+defaultStart, +UTC(inceptionDate.value));

    startDate.value = toISOstring(UTC(startDateMS));
    endDate.value = toISOstring(defaultEnd);
    selectedDate.value = toISOstring(defaultEnd);

    if (import.meta.env.VITE_SELECTED_DATE) {
      selectedDate.value = import.meta.env.VITE_SELECTED_DATE;
    }
    if (import.meta.env.VITE_START_DATE) {
      startDate.value = import.meta.env.VITE_START_DATE;
    }
    if (import.meta.env.VITE_END_DATE) {
      endDate.value = import.meta.env.VITE_END_DATE;
    }
  }

  const dateQuickSelectOptions = getQuickSelectOptions(
    inceptionDate,
    latestDate
  );

  watch(dateQuickSelectOptions, setDatesToLatest, { immediate: true });

  const allowedDateMinMs = computed(() => +UTC(inceptionDate.value));
  const allowedDateMaxMs = computed(() => +UTC(latestDate.value));

  function isDisabledDate(date: Date) {
    const dateMs = +UTC(date).startOf("day");
    return dateMs < allowedDateMinMs.value || dateMs > allowedDateMaxMs.value;
  }

  /******** STATE ********/
  const period = ref<"quarter" | "year">("quarter");
  const brokerComparisonTabModel = ref<
    "Summary" | "Revenues" | "Returns" | "Ratios" | "Balances"
  >("Summary");
  const brokerFocusTabModel = ref<
    "Summary" | "Revenues" | "Returns" | "Ratios" | "Balances"
  >("Summary");

  const revenueTypeModel = ref<(typeof revenueTypeOptions)[number]>("Total");

  const returnsTypeModel = ref<(typeof returnsTypeOptions)[number]>(
    "Wallet / Balance Sheet (RoA)"
  );
  const ratiosTypeModel = ref<(typeof ratiosTypeOptions)[number]>(
    "HTB vs Total Borrow"
  );

  const balancesTypeModel =
    ref<(typeof balancesTypeOptions)[number]>("Balance Sheet");

  const columTypeOptions = computed(() => [
    { title: "Annualized (USD) | Average", value: "value" },
    { title: "Rank", value: "rank" },
    {
      title: `${period.value === "year" ? "YTD (USD)" : "QTD (USD)"} | Current`,
      value: "ytd_curr",
    },
    { title: "% Of Total", value: "percent_of_total" },
  ]);
  const selectedColumnTypes = ref<ColumnType[]>([
    "value",
    "rank",
    "percent_of_total",
    "ytd_curr",
  ]);

  const focusedBrokerColumns = ref<ColumnType[]>([
    "value",
    "rank",
    "ytd_curr",
    "percent_of_total",
  ]);

  // Ensure order is maintained - for sticky columns
  const columnTypeOrder: ColumnType[] = [
    "value",
    "rank",
    "ytd_curr",
    "percent_of_total",
  ];
  watch(focusedBrokerColumns, (columns, oldColumns) => {
    if (columns.every((e, i) => e === oldColumns[i])) return;
    focusedBrokerColumns.value = focusedBrokerColumns.value.toSorted(
      (a, b) => columnTypeOrder.indexOf(a) - columnTypeOrder.indexOf(b)
    );
  });

  const anonymisedBrokerColumns = ref([
    "value",
    "percent_of_total",
    "rank",
    "ytd_curr",
  ]);

  const focusedBroker = ref<BrokerItem>(allBrokers[0]);
  const selectedBrokers = ref<BrokerItem[]>(allBrokers.slice(1));

  const isAnonymisedBrokerData = ref(false);
  const isAnonymisedBrokerName = ref(false);

  const dates = computed(() =>
    createDateMsRangeArray(startDate.value, endDate.value)
  );

  const randomArr = computed(() =>
    Array.from({ length: Math.floor(dates.value.length / 30) }, () => {
      return Math.floor(Math.random() * dates.value.length);
    })
  );

  const availablePeriods = computed(() => {
    return period.value === "quarter"
      ? [
          "2024 Q2",
          "2024 Q1",
          "2023 Q4",
          "2023 Q3",
          "2023 Q2",
          "2023 Q1",
          "2022 Q4",
          "2022 Q3",
        ]
      : ["2024", "2023", "2022"];
  });

  return {
    allowedDateMax: latestDate,
    allowedDateMin: inceptionDate,
    cachedDateRange,
    endDate,
    selectedDate,
    startDate,
    isDisabledDate,
    setPeriod,
    //
    period,
    brokerComparisonTabModel,
    brokerFocusTabModel,
    revenueTypeModel,
    returnsTypeModel,
    ratiosTypeModel,
    balancesTypeModel,
    selectedColumnTypes,
    columTypeOptions,
    focusedBroker,
    selectedBrokers,
    isAnonymisedBrokerName,
    isAnonymisedBrokerData,
    focusedBrokerColumns,
    anonymisedBrokerColumns,
    randomArr,
    availablePeriods,
  };
});
