import type { Dayjs } from "dayjs";

import type { DayJsInput } from "@/utils/UTC";
import { UTC } from "@/utils/UTC";

type CreateDayJSDateRangeArray = (
  startDate: DayJsInput,
  endDate: DayJsInput,
  step?: "day" | "year" | "quarter" | "month"
) => Dayjs[];

export const createDayJSDateRangeArray: CreateDayJSDateRangeArray = (
  startDate,
  endDate,
  step = "day"
): Dayjs[] => {
  const start = UTC(startDate).startOf(step);
  const dates = Array.from(
    { length: UTC(endDate).diff(start, step) + 1 },
    (_, i) => start.add(i, step)
  );
  return dates;
};

export const createDateRangeArray = (
  startDate: DayJsInput,
  endDate: DayJsInput
): string[] =>
  createDayJSDateRangeArray(startDate, endDate).map((date) =>
    date.format("YYYY-MM-DD")
  );

export const createDateMsRangeArray = (
  startDate: DayJsInput,
  endDate: DayJsInput
): number[] =>
  createDayJSDateRangeArray(startDate, endDate).map((date) => date.valueOf());
