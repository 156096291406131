import { captureMessage } from "@sentry/vue";

import { useUserSettingsStore } from "~/store/userSettings";

const sessionKey = "sessionSettings";

function getSession() {
  return JSON.parse(sessionStorage.getItem(sessionKey) ?? "{}");
}

export function useSetting<T>(
  defaultValue: T,
  /** Property name in saved settings object. Should be the same as the declared variable name for clarity */
  name: string,
  options?: {
    section?: SectionKey;
    autosave?: boolean;
    validator?: (value: unknown) => boolean;
  }
) {
  const value = ref(defaultValue);

  const userSettings = useUserSettingsStore();

  const section = options?.section ?? inject("sectionId", "");

  if (!section) {
    console.warn(
      `No section key passed to useSetting:${name}. Returning ref without save functionality`
    );

    return value;
  }

  function readSession() {
    const sessionSettings = getSession();
    const sectionSettings = sessionSettings[section];

    if (sectionSettings && name in sectionSettings) {
      value.value = sectionSettings[name];
      return;
    }

    readSetting();
  }

  function readSetting() {
    const sectionSettings = userSettings.data[section];

    if (!sectionSettings || !(name in sectionSettings)) {
      value.value = defaultValue;
      return;
    }

    const validator = options?.validator;

    if (!validator || validator(sectionSettings[name])) {
      value.value = sectionSettings[name];
    } else {
      value.value = defaultValue;

      captureMessage(
        `Invalid setting found, reverting to default. Setting: ${section}:${name}, Value: ${sectionSettings[name]}`
      );
      console.log(
        `Invalid setting found, reverting to default. Setting: ${section}:${name}, Value: ${sectionSettings[name]}`
      );

      clearSetting();
    }
  }

  function saveSession() {
    const sessionSettings = getSession();

    sessionSettings[section] ??= {};
    sessionSettings[section][name] = value.value;

    sessionStorage.setItem(sessionKey, JSON.stringify(sessionSettings));
  }

  function saveSetting() {
    const sectionSettings = userSettings.data[section];

    if (!sectionSettings || !deepEqual(sectionSettings[name], value.value)) {
      userSettings.data[section] ??= {};
      // Clone value to prevent sharing ref between objects
      userSettings.data[section][name] = JSON.parse(
        JSON.stringify(value.value)
      );

      userSettings.writeToDynamoDb();
    }
  }

  function clearSetting() {
    value.value = defaultValue;

    if (userSettings.data[section]) {
      delete userSettings.data[section][name];

      userSettings.writeToDynamoDb();
    }

    const sessionSettings = getSession();

    if (sessionSettings[section]) {
      delete sessionSettings[section][name];
      sessionStorage.setItem(sessionKey, JSON.stringify(sessionSettings));
    }
  }

  // Call before the events are setup to prevent a saved setting triggering a session save unnecessarily
  readSession();

  if (options?.autosave) {
    // Use flush:sync to prevent need for awaiting next tick in tests
    watch(value, saveSetting, { deep: true, flush: "sync" });
  } else {
    GlobalBus.$on("save-view", saveSetting);
    GlobalBus.$on("reset-view", readSetting);
    GlobalBus.$on("clear-view", clearSetting);

    // Use flush:sync to prevent need for awaiting next tick in tests
    watch(value, saveSession, { deep: true, flush: "sync" });
  }

  return value;
}
