<template>
  <div class="h-full">
    <div
      class="flex h-full items-center justify-end pl-1 pr-2"
      :class="color"
      aria-hidden="true"
    >
      <span class="text-xs font-bold">
        {{ displayText.replace("-", "").replace("+", "") }}
      </span>
      <div class="-mr-1 ml-1 mt-1">
        <ui-icon
          v-if="isNumber(value) && value"
          size="12"
          :icon="value < 0 ? mdiArrowDownBold : mdiArrowUpBold"
          color="currentcolor"
        />
      </div>
    </div>
    <!-- Display value with sign for screen readers -->
    <span class="sr-only">{{ displayText }}</span>
  </div>
</template>

<script setup="setup" lang="ts">
import { mdiArrowDownBold, mdiArrowUpBold } from "@mdi/js";

const props = defineProps<{
  value: unknown;
  numberFormatOptions: Intl.NumberFormatOptions;
}>();

const displayText = computed(() => {
  if (!isNumber(props.value)) {
    return "n/a";
  }

  const formatter = Intl.NumberFormat("en-US", {
    ...props.numberFormatOptions,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    notation: "compact",
  });

  return formatter.format(props.value);
});

const color = computed(() => {
  if (isNumber(props.value) && props.value) {
    return props.value < 0 ? "text-red-600" : "text-green-600";
  }
  return "text-gray-400";
});
</script>
