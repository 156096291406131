import type { Options } from "@/components/DataTable/dataTableTypes";

export function changeSedolToProductSedol(
  settings: PartialNonNullable<Options>
) {
  return {
    ...settings,
    selectedColumns: settings.selectedColumns.map((column) =>
      column === "sedol" ? "productSedol" : column
    ),
  };
}
