import type { BrokerItem } from "../types";

export const categoryColors = [
  "#3b82f6", //theme.blue[500],
  "#15803d", //theme.green[700],
  "#ea580c", //theme.orange[600],
  "#f59e0b", //theme.amber[500],
  "#14b8a6", //theme.teal[500],
  "#ef4444", //theme.red[500],
];

// TODO: Get from store
export const allBrokers: BrokerItem[] = [
  {
    title: "UBS",
    value: "ubs",
    color: categoryColors[0],
  },
  {
    title: "GS",
    value: "gs",
    color: categoryColors[1],
  },
  {
    title: "MS",
    value: "ms",
    color: categoryColors[2],
  },
  {
    title: "JPM",
    value: "jpm",
    color: categoryColors[3],
  },
  {
    title: "CITI",
    value: "citi",
    color: categoryColors[4],
  },
  {
    title: "BARC",
    value: "barc",
    color: categoryColors[5],
  },
].sort((a, b) => a.title.localeCompare(b.title));
