<template>
  <p
    v-if="cellValue === 'Multiple'"
    class="px-4 text-gray-500"
  >
    Multiple
  </p>
  <div
    v-else-if="displayItem"
    class="px-2"
  >
    <v-chip
      rounded
      :color="displayItem.color"
      density="comfortable"
      size="small"
    >
      {{ displayItem.text }}
    </v-chip>
  </div>
</template>

<script lang="ts" setup>
import type { DataTableColumn, DataTableItem } from "../dataTableTypes";

const props = defineProps<{
  column: DataTableColumn;
  item: DataTableItem;
  states: StateMap;
}>();

const cellValue = computed(() => props.item[props.column.value]);
const displayItem = computed(() => props.states.get(cellValue.value));
</script>

<script lang="ts">
export type StateMap = Map<any, { text: string; color: string }>;

export function getGroupValueBooleanSome(
  items: DataTableItem[],
  column: DataTableColumn
) {
  return items.map((e) => e[column.value]).some(Boolean);
}
export function getGroupValueBooleanEvery(
  items: DataTableItem[],
  column: DataTableColumn
) {
  return items.map((e) => e[column.value]).every(Boolean);
}
</script>
