import validate from "/home/runner/work/dashboard/dashboard/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45authentication_45global from "/home/runner/work/dashboard/dashboard/middleware/01.authentication.global.ts";
import _02_45setup_45global from "/home/runner/work/dashboard/dashboard/middleware/02.setup.global.ts";
import _03_45authorization_45global from "/home/runner/work/dashboard/dashboard/middleware/03.authorization.global.ts";
import _99_45track_45navigation_45global from "/home/runner/work/dashboard/dashboard/middleware/99.trackNavigation.global.ts";
import manifest_45route_45rule from "/home/runner/work/dashboard/dashboard/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45authentication_45global,
  _02_45setup_45global,
  _03_45authorization_45global,
  _99_45track_45navigation_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}