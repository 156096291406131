/**
 * Returns a copy of the object with the provided keys exluded
 */
export const omit = <T extends object, R extends keyof T>(
  object: T,
  keys: R[]
): Omit<T, R> =>
  keys.reduce(
    (acc, key) => {
      delete acc[key];
      return acc;
    },
    { ...object }
  );
