<template>
  <p
    v-if="isCurrencyError"
    class="px-4 text-red-500"
  >
    Currency Error
  </p>

  <DataTableCellDifferenceFormatter
    v-else-if="isDifference"
    :value="value"
    :number-format-options="useNumberFormatOptions"
  />

  <p
    v-else
    :class="[
      'text-right',
      'px-4',
      { 'text-negative': isNumber(value) && value < 0 },
    ]"
  >
    {{ displayText }}
  </p>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { isNumber } from "@/utils/isNumber";

import type { DataTableColumn, DataTableItem } from "../dataTableTypes";
import DataTableCellDifferenceFormatter from "./DataTableCellDifferenceFormatter.vue";

const props = defineProps<{
  column: DataTableColumn<any>;
  item: DataTableItem;
  items?: DataTableItem[];
  precision: [number, number];
  numberFormatOptions?: Intl.NumberFormatOptions;
  isDifference?: boolean;
  id: string;
}>();

const cell = computed<CurrencyCell | undefined>(
  () => props.item[props.column.value]
);

const value = computed(() => cell.value?.valueOf());

const currency = computed(() => cell.value?.getCurrency() ?? "USD");

const isCurrencyError = computed(() => {
  return cell.value?.isCurrencyError?.() ?? false;
});

const { usePrecision } = useAppStore();

const useNumberFormatOptions = computed<Intl.NumberFormatOptions>(() => ({
  currency: currency.value,
  minimumFractionDigits: usePrecision(props.precision),
  maximumFractionDigits: usePrecision(props.precision),
  ...props.numberFormatOptions,
}));

const displayText = computed(() => {
  if (!isNumber(value.value) || currency.value === "Total") {
    return "";
  }

  return Intl.NumberFormat("en-US", useNumberFormatOptions.value).format(
    value.value
  );
});

/** Error handling */

function logErrorInDevelopment() {
  if (!isCurrencyError.value) return;

  console.error(
    "Currency error in cell:\n",
    JSON.stringify(pick(props, ["column", "item"]), null, 2)
  );
}

const isDevelopment = import.meta.env.DEV;
if (isDevelopment) {
  watchEffect(logErrorInDevelopment);
}
</script>

<script lang="ts">
// Expose underlying logic so that it can be used in `useTransformToGrid`
export function getGroupValueCurrencyFromCells(items: CurrencyCell[]) {
  const values: CurrencyCell[] = items.filter(Boolean);

  const currencyValues = values.map((e) => e.getCurrency?.());

  const baseValues = values.map((e) => e.base).filter(isNumber);
  const localValues = isUnique(currencyValues)
    ? values.map((e) => e.local).filter(isNumber)
    : baseValues;

  return new CurrencyCell({
    base: baseValues.length ? sum(baseValues) : null,
    local: localValues.length ? sum(localValues) : null,
    currency: isUnique(currencyValues) ? currencyValues.at(0) : undefined,
  });
}

export function getGroupValueCurrency(
  items: DataTableItem[],
  column: DataTableColumn
) {
  return getGroupValueCurrencyFromCells(items.map((e) => e[column.value]));
}

export class CurrencyCell {
  base: number | null;
  local: number | null;
  currency?: string;

  constructor(
    cell: Omit<CurrencyCell, "getCurrency" | "valueOf" | "isCurrencyError">
  ) {
    this.base = cell.base;
    this.local = cell.local;
    this.currency = cell.currency;
  }

  getCurrency() {
    const { showPositionCurrency, isSingleCurrencyFilter, globalFilterParams } =
      useAppStore();

    if (!showPositionCurrency) return "USD";

    return isSingleCurrencyFilter
      ? globalFilterParams.currency[0]
      : this.currency;
  }

  valueOf() {
    if (this.isCurrencyError()) {
      return "Currency Error";
    }

    const { showPositionCurrency } = storeToRefs(useAppStore());

    const displayAsLocalCurrency =
      showPositionCurrency.value && this.getCurrency?.();

    return displayAsLocalCurrency ? this.local : this.base;
  }

  isCurrencyError() {
    const { showPositionCurrency, isSingleCurrencyFilter, globalFilterParams } =
      useAppStore();

    if (!showPositionCurrency || !isSingleCurrencyFilter || !this.currency) {
      return false;
    }

    return this.currency !== globalFilterParams.currency[0];
  }
}
</script>
