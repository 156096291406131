/**
 * Returns a copy of the object with only the keys given
 */
export function pick<T extends Record<any, any>, K extends keyof T>(
  object: T,
  keys: K[]
): Pick<T, K> {
  const output: any = {};
  keys.forEach((key) => (output[key] = object[key]));
  return output;
}
