import { unique } from "./unique";

export function deepEqual(a: unknown, b: unknown): boolean {
  if (a === b) return true;

  if (a && b && typeof a === "object" && typeof b === "object") {
    const keys = unique([...Object.keys(a), ...Object.keys(b)]);

    return keys.every((key) => {
      return deepEqual(a[key as keyof typeof a], b[key as keyof typeof a]);
    });
  }

  return false;
}
