<template>
  <div>
    <div class="mb-2 flex h-8 items-center text-xs text-gray-500">
      Filtering
      <v-chip
        v-if="
          selectedValues.length && selectedValues.length < itemValues.length
        "
        class="ml-4 text-accent"
        size="small"
        closable
        @click:close="selectedValues = []"
      >
        {{ selectedValues.length }}
      </v-chip>
    </div>
    <v-list class="max-h-80 overflow-scroll pt-0">
      <CheckboxSelectItem
        v-if="itemValues.length > 2"
        title="Select All"
        class="sticky top-0 z-10 mb-2 border-b bg-white"
        :model-value="allSelected"
        @select="selectAll"
      />
      <CheckboxSelectItem
        v-for="(item, index) in itemValues"
        :key="index"
        :class="{ italic: item === null }"
        :title="states.get(item)?.text ?? 'No Value'"
        :color="states.get(item)?.color"
        :model-value="selectedValues.includes(item)"
        @select="selectedValues = insertOrRemoveItem(selectedValues, item)"
      />
    </v-list>
  </div>
</template>

<script setup lang="ts">
import CheckboxSelectItem from "../_App/CheckboxSelectItem.vue";
import { createStringFilter } from "./DataTableFilterString.vue";
import type { DataTableColumn, DataTableItem } from "./dataTableTypes";
import { useTableOptions } from "./useTableOptions";

const props = defineProps<{
  column: DataTableColumn;
  items: DataTableItem[] | null;
  states: Map<any, { text: string; color: string }>;
}>();

const options = useTableOptions();

const selectedValues = ref<any[]>(
  options.columnFilters[props.column.value]?.state.values ?? []
);

useTrackEvent().watch({
  name: "Filter",
  title: props.column.title,
  value: () => selectedValues.value.length,
});

const itemValues = computed(() => {
  const stateValues = [...props.states.keys()];

  const includesEmptyValues = props.items?.some(
    (item) => item[props.column.value] === null
  );

  return includesEmptyValues ? [...stateValues, null] : stateValues;
});

const allSelected = computed(() =>
  itemValues.value.every((e) => selectedValues.value.includes(e))
);

function selectAll() {
  selectedValues.value = allSelected.value ? [] : [...itemValues.value];
}

const columnFilter = computed(() => {
  return createStringFilter(props.column.value, {
    values: allSelected.value ? [] : selectedValues.value,
  });
});

const columnFilters = computed(() => {
  const restFilters = omit(options.columnFilters, [props.column.value]);

  return columnFilter.value
    ? {
        ...restFilters,
        [props.column.value]: columnFilter.value,
      }
    : restFilters;
});

watch(columnFilter, (columnFilter) => {
  const externalFilter = options.columnFilters[props.column.value];

  if (deepEqual(externalFilter?.state, columnFilter?.state) === false) {
    options.columnFilters = columnFilters.value;
  }
});
</script>

<style scoped>
.v-list-item {
  font-size: 0.875rem;
  min-height: 2.5rem;
  padding-left: 0 !important;
}

:deep(.v-checkbox-btn) {
  --v-selection-control-size: 21px;
}
</style>
