import type { Dayjs } from "dayjs";

import { UTC } from "@/utils/UTC";

type DayJsInput = string | number | Dayjs | Date | undefined;

export const toISOstring = (date?: DayJsInput) => {
  return UTC(date).format("YYYY-MM-DD");
};

export const prettifyDate = (date?: DayJsInput) => {
  return UTC(date).format("DD MMM YYYY");
};
