import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin((nuxtApp) => {
  if (import.meta.env.TEST) return;
  const router = useRouter();
  const { sentry, isLocal, environment } = useRuntimeConfig().public;

  if (!isLocal && sentry.dsn) {
    printFormattedMessage([
      {
        text: "Setting up Sentry",
        style:
          "background: linear-gradient(90deg, rgba(117,76,135,1) 0%, rgba(30,22,49,1) 100%);",
      },
      {
        text: " ",
        style:
          "background-color: rgba(30,22,49,1);background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAACJfM0wAAAAAXNSR0IArs4c6QAAAPtJREFUOE/VlOsNwzAIhGGyNJO1mazJZNSHwCKOX6oUVeVPha18nA8o003BN3Hp92AReeF1zKy/o5hSbNAnEe1EdMzAZ8GSxG4Gfif4yswo0owhuLRARACGJevXYBF5EBFAu4PCWVf1UDFUBY8VZvnSU90EB9iGZnnOKbFieEmzkT0wGoYGwQ6HZ3/dEi9U+l0Fx6dGT+3jPBXWyKrqnuKoDkuhnsap6DXyAja1mAI0Kfso6QLz66oLry/jdwJXGqTjBkihVIv4knjRuDQlGCCs7hGagRxKcbfYnZ6F0UOuDfbvWlYAEAPrjADA43RWzvTUgoz+yWr3/wf+AAOzpBVYM7DQAAAAAElFTkSuQmCC'); background-size: 14px 14px;  background-repeat: no-repeat;",
      },
    ]);
    Sentry.init({
      app: nuxtApp.vueApp,
      dsn: sentry.dsn,
      environment: environment,
      integrations: [Sentry.browserTracingIntegration({ router })],
    });
  }
});
