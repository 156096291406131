import type { Options } from "@/components/DataTable/dataTableTypes";

// Force groupBy (used for ungrouped tables) to be the same as tableOptions.groupBy
export function syncUngroupedTableGroupByWithTableOptions(
  settings: PartialNonNullable<Options>,
  section: string,
  storage: Storage
): PartialNonNullable<Options> {
  const isPositionExplorerPage = [
    "analysis_positions",
    "cash_collateral_positions",
    "stock_loan_positions",
    "swaps_positions",
    "single_security_positions",
  ].includes(section);

  const currentToolbarSettings = JSON.parse(
    storage.getItem(`settings:${section}:toolbar`) || "{}"
  ) as PartialNonNullable<Options>;

  if (!isPositionExplorerPage) return settings;
  if (currentToolbarSettings.groupBy instanceof Array) return settings;

  // GroupBy has not been set so copy values from tableOptions
  const newToolbarSettings = {
    ...currentToolbarSettings,
    groupBy: settings.groupBy,
  };

  storage.setItem(
    `settings:${section}:toolbar`,
    JSON.stringify(newToolbarSettings)
  );

  return settings;
}
