import type { Options } from "@/components/DataTable/dataTableTypes";

export function removesPositionsColumns(settings: PartialNonNullable<Options>) {
  const toRemove = [
    "baseCurrency",
    "settleCurrency",
    "currentPriceLocalAmount",
    "currentPriceBaseAmount",
    "swapSpread",
    "swapBenchmarkRate",
    "stockLoanFee",
    "attributedSSPInterestBaseAmount",
    "attributedStockLoanInterestBaseAmount",
    "attributedTotalInterestBaseAmount",
    "unattributedStockLoanInterestBaseAmount",
    "unattributedStockLoanInterestLocalAmount",
    "unattributedSSPBenchmarkInterestBaseAmount",
    "unattributedSSPBenchmarkInterestLocalAmount",
    "attributedNetIndebtednessBaseAmount",
    "attributedNetIndebtednessLocalAmount",
    "attributedNetIndebtednessInterestBaseAmount",
    "attributedNetIndebtednessInterestLocalAmount",
    "spreadInterest",
    "attributedNISpreadRate",
  ];

  const groupBy = settings.groupBy.filter(
    (val: any) => !toRemove.includes(val)
  );
  const fixedColumns = settings.fixedColumns.filter(
    (val: any) => !toRemove.includes(val)
  );
  const selectedColumns = settings.selectedColumns.filter(
    (val: any) => !toRemove.includes(val)
  );

  return { ...settings, groupBy, fixedColumns, selectedColumns };
}
