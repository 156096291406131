<template>
  <v-menu
    :key="key"
    v-model="isOpen"
    bottom
    offset="10"
    transition="slide-y-transition"
    :close-on-content-click="false"
    width="600px"
  >
    <template #activator="{ props: activatorProps }">
      <UiIconBtn
        class="ml-2"
        size="40"
        v-bind="activatorProps"
        :track-event="{ name: 'Open Glossary' }"
      >
        {{ mdiTextBoxSearchOutline }}
      </UiIconBtn>
    </template>
    <InlineGlossaryCard />
  </v-menu>
</template>

<script lang="ts" setup>
import { mdiTextBoxSearchOutline } from "@mdi/js";

const InlineGlossaryCard = defineAsyncComponent(
  () => import("@/components/_App/InlineGlossaryCard.vue")
);

const isOpen = ref();

const key = ref(0);

const trackEvent = useTrackEvent();

let timer: ReturnType<typeof setTimeout>;
watch(isOpen, (isOpen) => {
  if (isOpen) {
    key.value++;
    timer = setTimeout(() => {
      trackEvent.trigger({
        name: "Open Glossary",
        component: "InlineGlossaryCard",
      });
    }, 5000);
  } else {
    clearTimeout(timer);
  }
});
</script>
