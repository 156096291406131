<template>
  <div class="text-center">
    <UiIconBtn
      v-if="visible"
      variant="positive"
      size="34"
      :aria-label
      :tooltip-text
      :tooltip-props="{ location: 'top', ...tooltipProps }"
      :track-event="{
        name: 'Action',
        component: 'DataTable',
        column: props.column.title || props.column.value,
      }"
      @click="handleClick"
    >
      {{ icon }}
    </UiIconBtn>
  </div>
</template>

<script setup lang="ts" generic="T extends DataTableItem">
import type { ComponentProps } from "vue-component-type-helpers";
import type { VTooltip } from "vuetify/components";

import type { DataTableColumn, DataTableItem } from "../dataTableTypes";

const props = withDefaults(
  defineProps<{
    column: DataTableColumn<T>;
    item: T;
    items?: T[];
    id: string;
    show?: (item: T, children?: T[]) => boolean;
    tooltipText?: string;
    tooltipProps?: ComponentProps<typeof VTooltip>;
    icon: string;
    ariaLabel?: string;
  }>(),
  {
    show: () => true,
  }
);

const visible = computed(() => props.show(props.item, props.items));

const providedAction = inject<ActionCallback<T>>(
  `datatable:action:${props.column.value}`
);

function handleClick() {
  providedAction?.(props.item, props.items);
}
</script>

<script lang="ts">
export type ActionCallback<T extends DataTableItem = DataTableItem> = (
  item: T,
  children?: T[]
) => void;

export function useProvideAction<T extends DataTableItem = DataTableItem>(
  column: string,
  callback: ActionCallback<T>
) {
  provide(`datatable:action:${column}`, callback);
}
</script>
