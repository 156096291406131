import type { Options } from "@/components/DataTable/dataTableTypes";

export function expectsGroupByAndSelectedColumnsToHaveNoOverlap(
  settings: PartialNonNullable<Options>
) {
  const selectedColumns = settings.selectedColumns.filter(
    (val: any) => !settings.groupBy.includes(val)
  );

  return { ...settings, selectedColumns };
}
