import type { AppSettings } from "@/store/app";

export function updateDefaultDateRange(
  settings: PartialNonNullable<AppSettings>
) {
  if (typeof settings.defaultDateRange === "string") {
    return settings;
  }

  if (deepEqual(settings.defaultDateRange, [1, "year"])) {
    settings.defaultDateRange = "Last 365 Days";
  }
  if (deepEqual(settings.defaultDateRange, [6, "months"])) {
    settings.defaultDateRange = "Last 90 Days";
  }
  if (deepEqual(settings.defaultDateRange, [3, "months"])) {
    settings.defaultDateRange = "Last 90 Days";
  }
  if (deepEqual(settings.defaultDateRange, [1, "month"])) {
    settings.defaultDateRange = "Last 30 Days";
  }
  if (deepEqual(settings.defaultDateRange, [2, "weeks"])) {
    settings.defaultDateRange = "Last 7 Days";
  }

  return settings;
}
