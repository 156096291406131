import type { DateStoreDefinitionSetup } from "./date.types";
import { useDate } from "./useDate";

export type UseDateMarketDataStore = typeof useDateMarketDataStore;

export const useDateMarketDataStore = defineStore("dateMarketData", () => {
  const availableDates = ref<string[]>([]);

  const allowedDateMax = computed(
    () => availableDates.value.at(-1) ?? toISOstring(UTC())
  );
  const allowedDateMin = computed(
    () => availableDates.value.at(0) ?? toISOstring(UTC())
  );

  const { startDate, endDate, selectedDate, cachedDateRange, setPeriod } =
    useDate();

  const { VITE_MARKET_DATA_START_DATE, VITE_MARKET_DATA_END_DATE } = import.meta
    .env;

  watch([allowedDateMin, allowedDateMax], ([min, max]) => {
    startDate.value = toISOstring(
      Math.max(+UTC(max).subtract(1, "year"), +UTC(min))
    );
    endDate.value = max;
    selectedDate.value = max;

    if (VITE_MARKET_DATA_START_DATE) {
      startDate.value = VITE_MARKET_DATA_START_DATE;
    }
    if (VITE_MARKET_DATA_END_DATE) {
      endDate.value = VITE_MARKET_DATA_END_DATE;
      selectedDate.value = VITE_MARKET_DATA_END_DATE;
    }
  });

  function isDisabledDate(date: Date) {
    // Handle conversion from Date object without being broken by timezones
    const isoString = toISOstring(UTC(date.toDateString()));

    return !availableDates.value.includes(isoString);
  }

  return {
    availableDates,
    startDate,
    endDate,
    selectedDate,
    allowedDateMax,
    allowedDateMin,
    cachedDateRange,
    isDisabledDate,
    setPeriod,
  };
}) satisfies DateStoreDefinitionSetup;
