import { createNumberFilter } from "@/components/DataTable/DataTableFilterNumber.vue";
import { createSearchFilter } from "@/components/DataTable/DataTableFilterSearch.vue";
import { createStringFilter } from "@/components/DataTable/DataTableFilterString.vue";
import type { ColumnFilter } from "@/components/DataTable/dataTableTypes";

export function hydrateColumnFilters(columnFilters: Dictionary<ColumnFilter>) {
  const lookup: Dictionary<
    (columnKey: string, state: any) => ColumnFilter | null
  > = {
    string: createStringFilter,
    number: createNumberFilter,
    search: createSearchFilter,
  };

  Object.keys(columnFilters).forEach((key) => {
    try {
      const { type, state } = columnFilters[key];
      const filter = lookup[type](key, state);

      filter ? (columnFilters[key] = filter) : delete columnFilters[key];
    } catch (e) {
      console.error(
        `Failed to create saved column filter: ${key}, ${JSON.stringify(
          columnFilters[key]
        )}`
      );

      delete columnFilters[key];
    }
  });

  return columnFilters;
}
