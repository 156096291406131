<template>
  <div>
    <div class="mb-4 flex h-8 items-center text-xs text-gray-500">
      Filtering
      <v-chip
        v-if="columnFilter"
        class="ml-4 text-accent"
        size="small"
        closable
        @click:close="selectedValues = []"
      >
        {{ selectedValues.length }}
      </v-chip>
    </div>

    <v-text-field
      v-model="search"
      class="-mt-6 mb-4"
      placeholder="Search"
      clearable
      @keydown.capture="escapeGlobalShortcuts"
    />

    <div class="sticky top-0 z-10 mb-2 border-b bg-white pb-2">
      <CheckboxSelectItem
        :title="search ? 'Select All Search Results' : 'Select All'"
        :model-value="allDisplayItemsSelected"
        @select="selectAll"
      />
    </div>

    <v-virtual-scroll
      :key="listItems.length"
      max-height="20rem"
      :min-width="`${maxWidthItem + 70}px`"
      :items="listItems"
    >
      <template #default="{ item: [item, title, description] }">
        <CheckboxSelectItem
          :title="item ? (title ?? '') : 'No Value'"
          :description
          :class="{ italic: !item }"
          :model-value="selectedValues.includes(item)"
          @select="selectedValues = insertOrRemoveItem(selectedValues, item)"
        />
      </template>
    </v-virtual-scroll>
  </div>
</template>

<script setup lang="ts">
import CheckboxSelectItem from "../_App/CheckboxSelectItem.vue";
import type {
  ColumnFilter,
  DataTableColumn,
  DataTableItem,
} from "./dataTableTypes";
import { useTableOptions } from "./useTableOptions";

const props = defineProps<{
  column: DataTableColumn;
  items: DataTableItem[] | null;
  formatter?: (value: any) => string;
}>();

const options = useTableOptions();

const search = ref("");

function isValidSearchItem(value: string) {
  const formatted = props.formatter?.(value);

  return (
    safeRegExp(search.value ?? "", "i").test(String(value)) ||
    (formatted && safeRegExp(search.value ?? "", "i").test(String(formatted)))
  );
}

const selectedValues = ref<string[]>(
  options.columnFilters[props.column.value]?.state.values ?? []
);

const trackEvent = useTrackEvent();

trackEvent.watch({
  name: "DataTable Selected Filters",
  column: props.column.title,
  value: () => selectedValues.value.length,
});

const staticValues = inject<MaybeRefOrGetter<string[]> | null>(
  `filter-values:${props.column.value}`,
  null
);

function applyColumnFilters(item: DataTableItem) {
  return Object.entries(options.columnFilters).every(
    ([columnValue, f]) => columnValue === props.column.value || f.filter(item)
  );
}

const filteredItems = computed(
  () => props.items?.filter((e) => applyColumnFilters(e) && !e.isTotal) ?? []
);

const itemValues = computed(() => {
  if (staticValues) {
    return toValue(staticValues);
  }

  const values = filteredItems.value
    .map((e) => String(e[props.column.value] ?? ""))
    .sort();

  return unique([...values, ...selectedValues.value]);
});

const displayItemValues = computed(() =>
  itemValues.value.filter(isValidSearchItem)
);

trackEvent.watch({
  name: "DataTable Filter Search",
  value: () => addLineBreaks(props.column.title),
});

const listItems = computed(() =>
  [...displayItemValues.value]
    .map((item) => {
      const formatted = props.formatter ? props.formatter(item) : item;
      const parts = (formatted?.split("|") ?? [null, undefined]) as [
        string | null,
        string | undefined,
      ];

      return [item, ...parts] as const;
    })
    .sort(([, a], [, b]) => {
      if (!a) return -1;
      if (!b) return 1;

      return a.localeCompare(b);
    })
);

const textMeasure = createTextMeasure("400 12px 'MarkOT'");
const maxWidthItem = computed(() =>
  Math.max(
    ...listItems.value.flatMap((e) => [
      textMeasure(e[1] ?? ""),
      textMeasure(e[2] ?? ""),
    ])
  )
);

const allDisplayItemsSelected = computed(() =>
  displayItemValues.value.every((e) => selectedValues.value.includes(e))
);

const allItemsSelected = computed(() => {
  if (staticValues) {
    return toValue(staticValues).length === selectedValues.value.length;
  }

  return props.items?.every((e) =>
    selectedValues.value.includes(String(e[props.column.value] ?? ""))
  );
});

function selectAll() {
  if (allDisplayItemsSelected.value) {
    selectedValues.value = selectedValues.value.filter(
      (e) => !isValidSearchItem(e)
    );
  } else {
    selectedValues.value = unique([
      ...selectedValues.value,
      ...displayItemValues.value,
    ]);
  }

  trackEvent.trigger({
    name: "DataTable Filter Select All",
    column: props.column.title,
    value: allDisplayItemsSelected.value,
  });
}

const columnFilter = computed(() => {
  return createStringFilter(props.column.value, {
    values: allItemsSelected.value ? [] : selectedValues.value,
  });
});

const columnFilters = computed(() => {
  const restFilters = omit(options.columnFilters, [props.column.value]);

  return columnFilter.value
    ? {
        ...restFilters,
        [props.column.value]: columnFilter.value,
      }
    : restFilters;
});

watch(columnFilter, (columnFilter) => {
  const externalFilter = options.columnFilters[props.column.value];

  if (deepEqual(externalFilter?.state, columnFilter?.state) === false) {
    options.columnFilters = columnFilters.value;
  }
});

function escapeGlobalShortcuts(e: KeyboardEvent) {
  if (e.key !== "Escape") e.stopPropagation();
}
</script>

<script lang="ts">
export function createStringFilter(
  columnKey: string,
  state: { values: unknown[] }
): ColumnFilter | null {
  if (!state.values.length) {
    return null;
  }

  return {
    type: "string",
    state,
    filter: (item) => state.values.includes(item[columnKey] ?? ""),
    count() {
      return state.values.length;
    },
  };
}
</script>

<style scoped>
.v-list-item {
  font-size: 0.875rem;
  min-height: 2.5rem;
  padding-left: 0 !important;
}

:deep(.v-text-field input::placeholder) {
  font-size: 0.875rem;
}

:deep(.v-checkbox-btn) {
  --v-selection-control-size: 21px;
}
</style>
