export function debounce<T extends (...args: any) => any>(
  fn: T,
  delay: number
) {
  let timeoutID: NodeJS.Timeout;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutID);
    timeoutID = setTimeout(() => fn.apply(this, args), delay);
  } as T;
}
