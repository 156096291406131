import type { Options } from "@/components/DataTable/dataTableTypes";

export function changeSecurityResolverSedolToSedol(
  settings: PartialNonNullable<Options>
) {
  return {
    ...settings,
    selectedColumns: settings.selectedColumns.map((column) =>
      column === "securityResolverSedol" ? "sedol" : column
    ),
  };
}
