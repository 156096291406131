<template>
  <div class="multi-select mb-1 ml-10 mr-4 flex justify-between">
    <v-list-item-title class="flex items-center text-neutral-500">
      {{ label }}
    </v-list-item-title>

    <v-select
      v-model="modelValue"
      :items="items"
      class="-mt-4 max-w-40"
      multiple
      :menu-props="{ contentClass: 'multi-select-content' }"
      v-bind="$attrs"
      density="comfortable"
      :placeholder="`0 / ${items.length}`"
    >
      <template #selection="{ index }">
        <span
          v-if="!index"
          class="text-sm"
        >
          {{ modelValue.length }} / {{ items.length }}
        </span>
      </template>
    </v-select>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  label: string;
  items: (string | { title: string; value: string })[];
  trackEvent: TrackEventProperties | undefined;
}>();

const modelValue = defineModel<string[]>({ required: true });

if (props.trackEvent) {
  useTrackEvent().watch({
    value: modelValue,
    ...props.trackEvent,
  });
}
</script>

<style>
.multi-select input::placeholder {
  font-size: 0.875rem !important;
}
.multi-select-content .v-list-item {
  padding-left: 0.25rem !important;
  font-size: 0.75rem;
}
.multi-select-content .v-list-item-title {
  font-size: 0.75rem !important;
}
</style>
