// Global
import type { AppSettings } from "@/store/app";

// TODO: We can probably remove this
export function removeOldAppSettingsLocal(
  settings: PartialNonNullable<AppSettings>,
  _section: string,
  storage: Storage
) {
  const appSettings = storage.getItem("appSettings");
  if (appSettings) {
    storage.removeItem("appSettings");
    console.info("Removed old app settings from local storage");
    return { ...settings, ...(<AppSettings>JSON.parse(appSettings)) };
  }
  return settings;
}
