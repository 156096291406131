export function createTextMeasure(font = "400 14px 'MarkOT'") {
  if (import.meta.env.NODE_ENV === "test") {
    return () => 100;
  }
  const canvas = new OffscreenCanvas(100, 100);
  const context = canvas.getContext("2d");

  return (text?: string) => {
    if (context && text) {
      context.font = font;
      const metrics = context.measureText(text);

      return metrics.width;
    }
    return 0;
  };
}
