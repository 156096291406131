import type { Dayjs } from "dayjs";

export type DateQuickSelectOption = {
  title:
    | "This Week"
    | "Past 2 Weeks"
    | "This Month"
    | "This Quarter"
    | "This Year"
    | "Last Week"
    | "Last Month"
    | "Last Quarter"
    | "Last Year"
    | "Last 7 Days"
    | "Last 30 Days"
    | "Last 90 Days"
    | "Last 365 Days"
    | "All Time";
  value: [Dayjs, Dayjs];
};

export function useDate() {
  const startDate = ref(toISOstring(UTC()));
  const endDate = ref(toISOstring(UTC()));
  const selectedDate = ref(toISOstring(UTC()));
  const cachedDateRange = ref<{ startDate: string; endDate: string }>();

  const selectedDateMs = computed(() => +UTC(selectedDate.value));
  const startDateMs = computed(() => +UTC(startDate.value));
  const endDateMs = computed(() => +UTC(endDate.value));

  watch(selectedDateMs, (selectedDateMs) => {
    if (selectedDateMs < startDateMs.value) {
      startDate.value = selectedDate.value;
    }
    if (selectedDateMs > endDateMs.value) {
      endDate.value = selectedDate.value;
    }
  });

  function setPeriod({
    startDate: newStartDate,
    endDate: newEndDate,
  }: {
    startDate: string;
    endDate: string;
  }) {
    // If end date is before start date, set both to start date value
    const _endDate = UTC(newEndDate).isAfter(newStartDate)
      ? newEndDate
      : newStartDate;

    // Update Start Date
    startDate.value = newStartDate;

    //  Update End Date
    endDate.value = _endDate;

    // Update Selected Date
    const isSelectedDateOutsidePeriod =
      UTC(selectedDate.value).isBefore(newStartDate) ||
      UTC(selectedDate.value).isAfter(_endDate);

    if (isSelectedDateOutsidePeriod) selectedDate.value = _endDate;

    cachedDateRange.value = undefined;
  }

  function getQuickSelectOptions(
    minDate: MaybeRefOrGetter<string>,
    maxDate: MaybeRefOrGetter<string>
  ) {
    const quickSelectOptions = computed<DateQuickSelectOption[]>(() => {
      const minUTC = UTC(toValue(minDate));
      const maxUTC = UTC(toValue(maxDate));

      return [
        {
          title: "This Week",
          value: [maxUTC.startOf("week"), maxUTC],
        },
        {
          title: "Past 2 Weeks",
          value: [maxUTC.subtract(1, "week").startOf("week"), maxUTC],
        },
        {
          title: "This Month",
          value: [maxUTC.startOf("month"), maxUTC],
        },
        {
          title: "This Quarter",
          value: [maxUTC.startOf("quarter"), maxUTC],
        },
        {
          title: "This Year",
          value: [maxUTC.startOf("year"), maxUTC],
        },
        {
          title: "Last Week",
          value: [
            maxUTC.subtract(1, "week").startOf("week"),
            maxUTC.subtract(1, "week").endOf("week"),
          ],
        },
        {
          title: "Last Month",
          value: [
            maxUTC.subtract(1, "month").startOf("month"),
            maxUTC.subtract(1, "month").endOf("month"),
          ],
        },
        {
          title: "Last Quarter",
          value: [
            maxUTC.subtract(1, "quarter").startOf("quarter"),
            maxUTC.subtract(1, "quarter").endOf("quarter"),
          ],
        },
        {
          title: "Last Year",
          value: [
            maxUTC.subtract(1, "year").startOf("year"),
            maxUTC.subtract(1, "year").endOf("year"),
          ],
        },
        {
          title: "Last 7 Days",
          value: [maxUTC.subtract(6, "days"), maxUTC],
        },
        {
          title: "Last 30 Days",
          value: [maxUTC.subtract(29, "days"), maxUTC],
        },
        {
          title: "Last 90 Days",
          value: [maxUTC.subtract(89, "days"), maxUTC],
        },
        {
          title: "Last 365 Days",
          value: [maxUTC.subtract(364, "days"), maxUTC],
        },
        {
          title: "All Time",
          value: [minUTC, maxUTC],
        },
      ];
    });

    return quickSelectOptions;
  }

  return {
    startDate,
    endDate,
    selectedDate,
    setPeriod,
    cachedDateRange,
    getQuickSelectOptions,
  };
}
