import tailwindcolors from "tailwindcss/colors";

// @ts-expect-error - can delete this
delete tailwindcolors.blueGray;
// @ts-expect-error - can delete this
delete tailwindcolors.coolGray;
// @ts-expect-error - can delete this
delete tailwindcolors.lightBlue;
// @ts-expect-error - can delete this
delete tailwindcolors.trueGray;
// @ts-expect-error - can delete this
delete tailwindcolors.warmGray;

export const theme = {
  ...tailwindcolors,
  primary: "rgb(0, 57, 31)",
  secondary: "rgb(139, 164, 157)",
  accent: "rgb(112, 108, 255)",
  background: "rgb(230, 235, 232)",
};

export const itemColors = [
  theme.green[700],
  theme.teal[700],
  theme.cyan[500],
  theme.blue[600],
  theme.purple[600],
  theme.pink[600],
  theme.red[600],
  theme.orange[600],
  theme.amber[600],
  theme.yellow[400],
];

export const categoryColors = [
  theme.blue[500],
  theme.green[700],
  theme.orange[600],
  theme.amber[500],
  theme.teal[500],
  theme.red[500],
];
