<template>
  <div class="w-[380px]">
    <div class="mb-4 flex h-8 items-center text-xs text-gray-500">
      Filtering
      <v-chip
        v-if="columnFilter?.count()"
        class="ml-4 text-accent"
        size="small"
        closable
        @click:close="clearAllFilters"
      >
        {{ columnFilter.count() }}
      </v-chip>
    </div>

    <div class="-mt-6 mb-4 grid grid-cols-[210px_1fr] gap-4">
      <UiSelect
        v-model="filterType1"
        :items="filterTypeOptions"
        :track-event="{
          name: 'Filter Type 1',
          component: 'DataTable',
          column: column.title,
        }"
      />
      <UiNumberField
        v-model="inputValue1"
        :precision
        :scale
        :prefix="filterPrefix"
        persistent-clear
        :track-event="{
          name: 'Filter Value 1',
          component: 'DataTable',
          column: column.title,
        }"
      />
    </div>

    <UiRadioGroup
      v-model="combinator"
      class="mb-6"
      inline
      :track-event="{
        name: 'Combination Type',
        component: 'DataTable',
        column: column.title,
      }"
    >
      <v-radio
        class="mr-4"
        label="And"
        value="and"
      />
      <v-radio
        class="mr-4"
        label="Or"
        value="or"
      />
    </UiRadioGroup>

    <div class="-mt-6 mb-4 grid grid-cols-[210px_1fr] gap-4">
      <UiSelect
        v-model="filterType2"
        :items="filterTypeOptions"
        :track-event="{
          name: 'Filter Type 2',
          component: 'DataTable',
          column: column.title,
        }"
      />
      <UiNumberField
        v-model="inputValue2"
        :precision
        :scale
        :prefix="filterPrefix"
        persistent-clear
        :track-event="{
          name: 'Filter Value 2',
          component: 'DataTable',
          column: column.title,
        }"
      />
    </div>

    <v-list-item
      class="pl-0 pr-2"
      :ripple="false"
      @click="excludeNulls = !excludeNulls"
    >
      <div class="flex items-center">
        <v-checkbox-btn
          v-model="excludeNulls"
          class="pointer-events-none mr-2 grow-0"
          tabindex="-1"
        />
        <em class="text-sm">Exclude Items With No Value</em>
      </div>
    </v-list-item>
  </div>
</template>

<script setup lang="ts">
import { useAppStore } from "~/store/app";

import type {
  ColumnFilter,
  DataTableColumn,
  DataTableItem,
  ItemFilterPredicate,
} from "./dataTableTypes";
import { useTableOptions } from "./useTableOptions";

const props = defineProps<{
  column: DataTableColumn;
  items: DataTableItem[] | null;
  precision: [number, number];
  numberFormatOptions?: Intl.NumberFormatOptions;
  currencyKey?: string;
}>();

const options = useTableOptions();

const { usePrecision } = useAppStore();

const precision = computed(() => usePrecision(props.precision));

const filterPrefix = computed(() => {
  const style = props.numberFormatOptions?.style;

  if (style === "percent") {
    return "%";
  }

  if (style === "currency" && !props.currencyKey) {
    return "USD";
  }
  return undefined;
});

const scale = computed(() =>
  props.numberFormatOptions?.style === "percent"
    ? 100
    : (props.column.cellProps.scale ?? 1)
);

const filterTypeOptions: { title: string; value: Operator }[] = [
  { title: "Equal to", value: "eq" },
  { title: "Not Equal to", value: "ne" },
  { title: "Greater than", value: "gt" },
  { title: "Greater than or Equal to", value: "gte" },
  { title: "Less than", value: "lt" },
  { title: "Less than or Equal to", value: "lte" },
];

const filterType1 = ref<Operator>("eq");
const filterType2 = ref<Operator>("eq");

const inputValue1 = ref<number>();
const inputValue2 = ref<number>();

const combinator = ref<Combinator>("and");
const excludeNulls = ref(false);

function clearAllFilters() {
  inputValue1.value = undefined;
  inputValue2.value = undefined;
  excludeNulls.value = false;
}

const columnFilter = computed(() => {
  const conditions = [
    { operator: filterType1.value, value: inputValue1.value },
    { operator: filterType2.value, value: inputValue2.value },
  ].filter((e): e is { operator: Operator; value: number } =>
    isNumber(e.value)
  );

  return createNumberFilter(props.column.value, {
    conditions,
    combinator: combinator.value,
    excludeNulls: excludeNulls.value,
    precision: props.precision,
  });
});

watch(columnFilter, (columnFilter) => {
  const { columnFilters } = options;
  const externalFilter = columnFilters[props.column.value];

  if (deepEqual(externalFilter?.state, columnFilter?.state) === false) {
    const restFilters = omit(columnFilters, [props.column.value]);
    const update = columnFilter
      ? { ...restFilters, [props.column.value]: columnFilter }
      : restFilters;

    options.columnFilters = update;
  }
});

const trackEvent = useTrackEvent();

watch(excludeNulls, (excludeNulls) =>
  trackEvent.trigger({
    name: "Exclude Empty Values",
    column: props.column.title,
    value: excludeNulls,
  })
);

function receiveExternalFilter() {
  const columnFilter = options.columnFilters[props.column.value];

  if (columnFilter?.state) {
    const state = columnFilter.state;

    inputValue1.value = state.conditions[0]?.value;
    inputValue2.value = state.conditions[1]?.value;
    filterType1.value = state.conditions[0]?.operator;
    filterType2.value = state.conditions[1]?.operator;
    combinator.value = state.combinator;
    excludeNulls.value = state.excludeNulls;
  }
}

onMounted(receiveExternalFilter);
</script>

<script lang="ts">
type Operator = "eq" | "ne" | "gt" | "gte" | "lt" | "lte";
type Combinator = "and" | "or";

const predicateLookup: Record<
  Operator,
  (value: number, input: number) => boolean
> = {
  eq: (value, input) => value === input,
  ne: (value, input) => value !== input,
  gt: (value, input) => isNumber(value) && value > input,
  gte: (value, input) => isNumber(value) && value >= input,
  lt: (value, input) => isNumber(value) && value < input,
  lte: (value, input) => isNumber(value) && value <= input,
};

export function createNumberFilter(
  columnKey: string,
  state: {
    combinator: Combinator;
    conditions: { operator: Operator; value: number }[];
    excludeNulls: boolean;
    precision: [number, number];
  }
): ColumnFilter | null {
  if (!state.conditions.length && !state.excludeNulls) {
    return null;
  }

  const { usePrecision } = useAppStore();

  const filter: ItemFilterPredicate = (item) => {
    const itemValue = item[columnKey];

    const fractionDigits = usePrecision(state.precision);

    const precisionValue = isNumber(itemValue)
      ? toFixedNumber(itemValue, fractionDigits)
      : itemValue;

    if (state.excludeNulls && !isNumber(itemValue)) {
      return false;
    }

    const conditionResults = state.conditions.map(({ operator, value }) => {
      return predicateLookup[operator](precisionValue, value);
    });

    return state.combinator === "and"
      ? conditionResults.every(Boolean)
      : conditionResults.some(Boolean);
  };

  return {
    type: "number",
    state,
    filter,
    count() {
      return state.conditions.length + Number(state.excludeNulls);
    },
  };
}
</script>
