import { useAppStore } from "~/store/app";

export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.client) {
    const appStore = useAppStore();

    // TODO: move back into setup middleware when auth flag is no longer needed
    if (!appStore.envMeta.client) {
      await $fetch("/api/meta/", {
        onResponse: (e) => {
          appStore.envMeta = e.response._data;

          const { environment, version, release_timestamp } = appStore.envMeta;
          const timestampIso = toISOstring(UTC(release_timestamp));

          printFormattedMessage([
            {
              text: "Zeus",
              style: `background-color: ${theme.primary}; color: white; padding-right: 0px !important;`,
            },
            {
              text: `- Environment: ${environment} - Version: ${version} - Timestamp: ${timestampIso}`,
              style: `background-color: ${theme.primary}; color: white;`,
            },
          ]);
        },
      }).catch((e) => {
        throw createError({
          data: e,
          message: [
            "There was an error during the setup of the application",
            "Please try again or contact the system admin if the problem persists",
          ].join("\n"),
        });
      });
    }

    if (to.path === "/logout") {
      const logoutURL = await $fetch("/auth/logout");
      return navigateTo(logoutURL, { external: true });
    }

    if (!useAppStore().envMeta.feature_flags.use_nuxt_auth) {
      return;
    }

    if (!useAuth().loggedIn) {
      // Do not set next cookie if its already present (redirected from django or admin-ui)
      const cookies = document.cookie.split(";");
      const next = cookies.some((e) => e.trim().startsWith("next"))
        ? null
        : location.href;

      const loginURL = await $fetch("/auth/login", {
        params: { next },
      });
      return navigateTo(loginURL, { external: true });
    }
  }
});
