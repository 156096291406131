<script setup lang="ts">
import titleUrl from "@/assets/kayenta_title.svg";
import type { NuxtError } from "#app";

defineProps({
  error: Object as () => NuxtError,
});

const defaultMessage = [
  "Something went wrong",
  "Please try again or contact the system admin if the problem persists",
].join("\n");
</script>

<template>
  <div class="flex h-screen flex-col items-center justify-center gap-8">
    <div>
      <v-img
        :src="titleUrl"
        class="w-[200px]"
      />
    </div>

    <p class="whitespace-pre-wrap text-center leading-8">
      {{ error?.message ?? defaultMessage }}
    </p>
  </div>
</template>
