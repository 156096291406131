import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat.js";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter.js";
import quarterOfYear from "dayjs/plugin/quarterOfYear.js";
import utc from "dayjs/plugin/utc.js";

dayjs.extend(isSameOrAfter);
dayjs.extend(quarterOfYear);
dayjs.extend(advancedFormat);
dayjs.extend(utc);

export type DayJsInput = Dayjs | string | number | Date | undefined;

// Warning: Using a much later date, such as 10000-12-31 results in NaN when using firefox
export const maxSafeDateMs = +dayjs("3000-12-31");

// set keepLocalTime to true - this makes date select, apis, line charts and tables all work together
export const UTC = (a?: DayJsInput) => dayjs(a).utc(true);
