import { omit } from "@/utils/omit";

export function removeMarketDataActivityIsQuantity(
  settings: Dictionary<any>,
  section: string
) {
  if (section === "market_data_single_security") {
    return omit(settings, ["activityIsQuantity", "inventoryIsQuantity"]);
  }
  return settings;
}
