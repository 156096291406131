import type { AppSettings } from "@/store/app";

export function updatePrecisionSettings(
  settings: PartialNonNullable<AppSettings>
) {
  delete settings["precisionAccruals" as keyof AppSettings];
  delete settings["precisionBalances" as keyof AppSettings];
  delete settings["precisionPrices" as keyof AppSettings];
  delete settings["precisionRates" as keyof AppSettings];
  delete settings["precisionQuantities" as keyof AppSettings];

  return settings;
}
