<template>
  <v-fade-transition>
    <div class="absolute top-0 w-full">
      <v-progress-linear
        v-show="pendingRequests.size"
        color="accent"
        class="!rounded-none"
        background-color="background"
        height="3"
        indeterminate
      />
    </div>
  </v-fade-transition>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";

const { pendingRequests } = storeToRefs(useAppStore());
</script>
