const uniqueBy = <T>(arr: T[], callback: (item: T) => any) => {
  const map = new Map();

  for (const item of arr) {
    const key = callback(item);
    map.has(key) || map.set(key, item);
  }

  return [...map.values()];
};

/**
 * Returns an array with unique values, takes an optional call back which is used to determine uniqueness
 * @param arr
 */
export const unique = <T>(arr: T[], callback?: (item: T) => any): T[] => {
  return callback ? uniqueBy(arr, callback) : [...new Set(arr)];
};

/**
 * Returns a boolean indicating if all values in the array are the same  takes an optional call back which is used to determine uniqueness
 * @param arr
 */
export const isUnique = <T>(arr: T[], callback?: (item: T) => any) => {
  const len = arr.length;

  if (len === 0) return true;

  if (callback) {
    const first = callback(arr[0]);
    for (let i = 1; i < len; i++) {
      if (callback(arr[i]) !== first) return false;
    }
  } else {
    const first = arr[0];
    for (let i = 1; i < len; i++) {
      if (arr[i] !== first) return false;
    }
  }
  return true;
};
