import { captureException } from "@sentry/vue";
import type { AwsRumConfig } from "aws-rum-web";
import { AwsRum } from "aws-rum-web";

import { printFormattedMessage } from "@/utils/printFormattedMessage";
import { useAppStore } from "~/store/app";

// eslint-disable-next-line import/no-mutable-exports
export let awsRum: AwsRum | null = null;

export function useAwsRum() {
  if (import.meta.env.TEST) return { awsRum };

  if (awsRum) {
    return { awsRum };
  }

  const runTimeConfig = useRuntimeConfig();
  const { guestRoleArn, identityPoolId, rumApplicationId } =
    runTimeConfig.public;

  const config: AwsRumConfig = {
    sessionSampleRate: 1,
    guestRoleArn,
    identityPoolId,
    endpoint: "https://dataplane.rum.eu-west-1.amazonaws.com",
    telemetries: ["performance", "errors", "http"],
    allowCookies: true,
    enableXRay: false,
    dispatchInterval: 5000,
    sessionEventLimit: 0,
    disableAutoPageView: true,
  };

  try {
    awsRum = new AwsRum(rumApplicationId, "1.0.0", "eu-west-1", config);
  } catch (error) {
    console.error(error);
    // Send error to Sentry
    captureException(error);
  }

  printFormattedMessage([
    {
      text: `AWS RUM Web client | ${rumApplicationId}`,
      style: "background-color: #FF9900; color: black;",
    },
    {
      text: awsRum ? "Loaded" : "Not Loaded",
      style: "background-color: black; color: #FF9900;",
    },
  ]);

  const appStore = useAppStore();

  const { isStaffOverride, userProfile } = storeToRefs(appStore);

  watchEffect(() => {
    const props = {
      is_staff: isStaffOverride.value,
      email: userProfile.value?.email ?? "NO USERPROFILE EMAIL",
    };
    awsRum?.addSessionAttributes(props);
  });

  return { awsRum };
}
