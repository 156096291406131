<template>
  <DataTableCellDifferenceFormatter
    v-if="isDifference"
    :value
    :number-format-options="useNumberFormatOptions"
  />
  <p
    v-else
    :class="[
      'px-4 text-right',
      { 'text-negative': isNegative, 'text-gray-500': isMultiple },
    ]"
  >
    {{ displayText }}
  </p>
</template>

<script setup lang="ts">
import type {
  DataTableColumn,
  DataTableItem,
} from "@/components/DataTable/dataTableTypes";
import { useAppStore } from "~/store/app";

import DataTableCellDifferenceFormatter from "./DataTableCellDifferenceFormatter.vue";
import { getGroupValueUnique } from "./DataTableCellString.vue";

const props = withDefaults(
  defineProps<{
    column: DataTableColumn;
    item: DataTableItem;
    items?: DataTableItem[];
    currencyKey?: string;
    numberFormatOptions?: Intl.NumberFormatOptions;
    precision: [number, number];
    scale?: number;
    isDifference?: boolean;
    id: string;
  }>(),
  {
    scale: 1,
  }
);

const { usePrecision } = useAppStore();

const useNumberFormatOptions = computed<Intl.NumberFormatOptions>(() => {
  const currency = props.currencyKey ? props.item[props.currencyKey] : "USD";

  const useCurrency = currency && currency !== "Multiple" ? currency : "USD";

  const precision = usePrecision(props.precision);

  const precisionAdjust =
    props.numberFormatOptions?.style === "percent" ? -2 : 0;

  return {
    minimumFractionDigits: precision + precisionAdjust,
    maximumFractionDigits: precision + precisionAdjust,
    currency: useCurrency,
    ...props.numberFormatOptions,
  };
});

const value = computed(() => {
  const value: unknown = props.item[props.column.value];
  if (typeof value === "number" && isNaN(value)) {
    return "";
  }

  if (typeof value === "number") {
    return value * props.scale;
  }

  return String(value ?? "");
});

const isNegative = computed(
  () => typeof value.value === "number" && value.value < 0
);

const isMultiple = computed(
  () => typeof value.value === "string" && value.value === "Multiple"
);

const displayText = computed(() => {
  if (typeof value.value === "string") return value.value;

  return Intl.NumberFormat("en-US", useNumberFormatOptions.value).format(
    value.value
  );
});
</script>

<script lang="ts">
type GroupValueFn<T extends DataTableItem> = (
  items: T[],
  column: DataTableColumn<T>
) => string | number | undefined;

export function escapeMultiCcy<T extends DataTableItem>(
  fn: GroupValueFn<T>
): GroupValueFn<T> {
  return (items, column) => {
    const { currencyKey } = column.cellProps;

    return !currencyKey || isUnique(items, (e) => e[currencyKey])
      ? fn(items, column)
      : undefined;
  };
}

export function escapeNonAggregatingQuantities<T extends DataTableItem>(
  fn: GroupValueFn<T>
): GroupValueFn<T> {
  return (items, column) => {
    const sameIdentifier = isUnique(
      items,
      (e) => e.productIsin ?? e.productCusip
    );
    const samePositionType = isUnique(items, (e) => e.positionType);
    const allCash = items.every((e) => e.isCash);

    return (sameIdentifier && samePositionType) || allCash
      ? escapeMultiCcy(fn)(items, column)
      : undefined;
  };
}

export function getGroupValueAggregateNumber<T extends DataTableItem>(
  items: T[],
  column: DataTableColumn<T>
) {
  let value: number | undefined = undefined;

  // Intentionally does not use `sum` helper as optimization
  for (let i = 0; i < items.length; i++) {
    const itemValue = items.at(i)?.[column.value] as number | null;

    if (isNumber(itemValue)) {
      value = (value ?? 0) + itemValue;
    }
  }

  return value;
}

export function getPositionTypeWeightedAverageRate<T extends Dictionary>(
  defaultWeightKey: NumberOrNullOnly<T>,
  swapWeightKey: NumberOrNullOnly<T> = defaultWeightKey
) {
  return (items: T[], column: DataTableColumn<T>) => {
    const hasValues = items.some((e) => isNumber(e[column.value]));

    if (!hasValues) {
      return null;
    }

    const isUniquePositionType = isUnique(items, (e) => e.positionType);

    if (!isUniquePositionType) {
      return getGroupValueUnique(items, column);
    }

    const weightKey = ["Short Swap", "Long Swap"].includes(
      items[0].positionType
    )
      ? swapWeightKey
      : defaultWeightKey;

    const [totalNotional, totalInterest] = items.reduce(
      ([tNotional, tInterest], item) => [
        tNotional + (item[weightKey] ?? 0),
        tInterest + (item[weightKey] ?? 0) * Number(item[column.value] ?? 0),
      ],
      [0, 0]
    );

    // +0 converts -0 values to 0
    const result = totalInterest / totalNotional + 0;

    return isNaN(result) ? null : result;
  };
}
</script>
