<template>
  <v-text-field
    v-bind="{ ...$attrs, clearable, persistentClear }"
    v-model:focused="focused"
    v-set-bounds
    :model-value="focused ? localValue : formattedValue"
    :type="focused ? 'number' : 'text'"
    density="comfortable"
    @update:model-value="onUpdate"
    @keydown.capture="escapeGlobalShortcuts"
  >
    <template #prepend-inner>
      <slot name="prepend-inner" />
    </template>
    <template #append-inner>
      <slot name="append-inner" />
      <span v-if="validationError">
        <v-tooltip
          :model-value="true"
          activator="parent"
          location="top left"
        >
          <v-icon
            :icon="mdiAlertBox"
            size="small"
            class="-mt-1 mr-2 text-red-600"
          />
          {{ validationError }}
        </v-tooltip>
      </span>
    </template>
    <template
      v-if="$slots.append"
      #append
    >
      <slot name="append" />
    </template>
    <template
      v-if="(clearable && localValue !== undefined) || persistentClear"
      #clear
    >
      <button
        class="inline-flex size-6 items-center justify-center rounded bg-red-50 text-red-400"
        @click="onUpdate(null)"
      >
        <v-icon
          :icon="mdiClose"
          size="x-small"
        />
      </button>
    </template>
  </v-text-field>
</template>

<script setup="setup" lang="ts">
import { mdiAlertBox, mdiClose } from "@mdi/js";
import { computed, ref, watch, watchEffect } from "vue";
// Explicit import is needed for some reason here otherwise the component is missing from the build
import { VIcon, VTextField, VTooltip } from "vuetify/lib/components/index.mjs";

import { useTrackEvent } from "@/composables/useTrackEvent";
import { clamp } from "@/utils/clamp";
import { isNumber } from "@/utils/isNumber";
import { toFixedNumber } from "@/utils/toFixedNumber";

const props = withDefaults(
  defineProps<{
    min?: number;
    max?: number;
    step?: number;
    scale?: number;
    precision?: number;
    clearable?: boolean;
    persistentClear?: boolean;
    undefinedValue?: number;
    trackEvent: TrackEventProperties | undefined;
  }>(),
  {
    min: -Infinity,
    max: Infinity,
    precision: 20,
    scale: 1,
    step: 1,
    undefinedValue: undefined,
  }
);

const modelValue = defineModel<number | undefined>({ required: true });

const localValue = ref<string>();

const focused = ref(false);

const formatter = Intl.NumberFormat("en-US", {
  useGrouping: true,
  maximumFractionDigits: props.precision,
});

const formattedValue = computed(() =>
  localValue.value === undefined ? "" : formatter.format(+localValue.value)
);

function toPrecision(value: number) {
  return toFixedNumber(value, props.precision);
}

function onUpdate(update: string | null) {
  if (!update) {
    localValue.value = undefined;
    modelValue.value = props.undefinedValue;
    return;
  }

  localValue.value = update;

  modelValue.value = toPrecision(
    clamp(+update, props.min, props.max) / props.scale
  );
}

// Apply clamp and precision changes when user is not making changes
watchEffect(() => {
  if (focused.value) return;

  localValue.value = isNumber(modelValue.value)
    ? String(toPrecision(modelValue.value * props.scale))
    : modelValue.value;
});

// vuetify text-field does not provide prop for setting minmax on number inputs
function vSetBounds(el: HTMLDivElement) {
  const input = el.querySelector("input");

  input?.setAttribute("min", String(props.min));
  input?.setAttribute("max", String(props.max));
  input?.setAttribute("step", String(props.step));
}

const validationError = computed(() => {
  if (localValue.value === undefined) {
    return undefined;
  }
  if (+localValue.value < props.min) {
    return `Number is less than allowed minimum (${props.min})`;
  }
  if (+localValue.value > props.max) {
    return `Number is greater than allowed maximum (${props.max})`;
  }

  return undefined;
});

function escapeGlobalShortcuts(e: KeyboardEvent) {
  if (e.key !== "Escape") e.stopPropagation();
}

/** Event tracking **********/

if (props.trackEvent) {
  const trackValue = ref();

  watch(
    modelValue,
    (modelValue) => {
      if (focused.value) trackValue.value = modelValue;
    },
    { immediate: true }
  );

  useTrackEvent().watch({
    value: trackValue,
    element: "number-field",
    ...props.trackEvent,
  });
}
</script>

<style scoped>
:deep(.v-text-field__prefix__text) {
  font-size: 0.75rem;
  color: theme("colors.gray.500");
  margin-top: 6px;
}

:deep(.v-field__clearable) {
  margin-left: 0;
}
:deep(input) {
  font-size: 0.875rem;
}
</style>
