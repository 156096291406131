/**
 * Returns a RegExp object from a string, escaping unsafe characters
 * @param str
 * @param flags
 */
export const safeRegExp = (str: string, flags?: string) => {
  const cleanedSearch = str
    // Escape unsafe characters
    .replace(/[\\?[()]/g, (substring) => "\\" + substring)
    // Remove extra whitespace
    .trim();

  return new RegExp(cleanedSearch, flags);
};
