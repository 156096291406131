type ColorObj = {
  r: number;
  g: number;
  b: number;
};

export const isHex = (str: string) => {
  const regex = /^#([0-9A-F]{6})/i;
  return regex.test(str);
};

export const hexToObj = (hex: string) => {
  // q = 0xRRGGBB
  const q = parseInt(hex.slice(1), 16);

  const colorObj = {
    // Shift right 16 bits to get red value
    r: q >> 16,
    // Shift right 8 bits and mask with 0xFF to get green value
    g: (q >> 8) & 255,
    // Mask with 0xFF to get blue value
    b: q & 255,
  };
  return colorObj;
};

export const mixColorObjects = (
  colorObj1: ColorObj,
  colorObj2: ColorObj,
  amount: number
) => {
  const colorObj = {
    r: Math.round(colorObj1.r * (1 - amount) + colorObj2.r * amount),
    g: Math.round(colorObj1.g * (1 - amount) + colorObj2.g * amount),
    b: Math.round(colorObj1.b * (1 - amount) + colorObj2.b * amount),
  };
  return colorObj;
};

export const objToHex = (obj: ColorObj) => {
  const r = obj.r.toString(16).padStart(2, "0");
  const g = obj.g.toString(16).padStart(2, "0");
  const b = obj.b.toString(16).padStart(2, "0");

  const newHex = "#" + r + g + b;
  return newHex;
};

/**
 * Mixes together two 6 digit hex colors by the provided amount from 0 to 1
 * @param hex1
 * @param hex2
 * @param amount
 */
const mixHexColors = (hex1: string, hex2: string, amount: number) => {
  if (!isHex(hex1)) {
    throw `Input hex string is not valid; hex1: ${hex1}`;
  }
  if (!isHex(hex2)) {
    throw `Input hex string is not valid; hex2: ${hex2}`;
  }
  if (amount < 0 || amount > 1) {
    throw `Input amount is not valid; amount: ${amount}`;
  }

  const colorObj1 = hexToObj(hex1);
  const colorObj2 = hexToObj(hex2);
  const newColorObj = mixColorObjects(colorObj1, colorObj2, amount);
  const newHex = objToHex(newColorObj);
  return newHex;
};

// colorLighten takes a color and an amount and returns a lighter version of the color
const lightenHexColor = (hex: string, amount: number) => {
  return mixHexColors(hex, "#ffffff", amount);
};

// colorDarken takes a color and an amount and returns a darker version of the color
const darkenHexColor = (hex: string, amount: number) => {
  return mixHexColors(hex, "#000000", amount);
};

export const colorMixer = {
  mix: mixHexColors,
  lighten: lightenHexColor,
  darken: darkenHexColor,
};
