<template>
  <div>
    <p class="mb-4 text-xs text-gray-500">Filtering</p>
    <v-text-field
      :model-value="search"
      class="-mt-6"
      placeholder="Search"
      clearable
      @update:model-value="search = $event ?? ''"
    />
  </div>
</template>

<script setup lang="ts">
import type {
  ColumnFilter,
  DataTableColumn,
  DataTableItem,
} from "@/components/DataTable/dataTableTypes";

import { useTableOptions } from "./useTableOptions";

const props = defineProps<{
  column: DataTableColumn;
  items: DataTableItem[] | null;
}>();

const options = useTableOptions();

const search = ref(
  options.columnFilters[props.column.value]?.state.value ?? ""
);

function updateFilters(search: string) {
  const restFilters = omit(options.columnFilters, [props.column.value]);
  const columnFilter = createSearchFilter(props.column.value, {
    value: search,
  });
  const externalFilter = options.columnFilters[props.column.value];

  if (deepEqual(externalFilter?.state, columnFilter?.state) === false) {
    options.columnFilters = columnFilter
      ? {
          ...restFilters,
          [props.column.value]: columnFilter,
        }
      : restFilters;
  }
}

watch(search, debounce(updateFilters, 500));

const trackEvent = useTrackEvent();

watch(
  () => search.value.length > 0,
  (isSearch) => {
    if (isSearch) trackEvent.trigger({ name: `${props.column.title}: Filter` });
  }
);
</script>

<script lang="ts">
export function createSearchFilter(
  columnKey: string,
  state: { value: string }
): ColumnFilter | null {
  const trimmedSearch = state.value.trim();

  if (!trimmedSearch) {
    return null;
  }

  return {
    type: "search",
    state,
    filter: (item) => safeRegExp(trimmedSearch).test(item[columnKey]),
    count() {
      return trimmedSearch ? 1 : 0;
    },
  };
}
</script>

<style scoped>
:deep(.v-text-field input) {
  font-size: 0.875rem;
}
</style>
