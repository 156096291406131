import dayjs from "dayjs";

import { sectionTitleKey } from "@/components/ContentBlock/contentBlockInjectionKeys";
import { useAppStore } from "~/store/app";
import { getBreakpoint } from "~/utils/getBreakpoint";

export type TrackEventProperties = TriggerOptions | undefined;

export type EventTracker = (args: Dictionary<unknown>) => void;

export type TriggerOptions = {
  name: string;
} & Dictionary<MaybeRefOrGetter<unknown>>;

export type WatchOptions = {
  /** Ignore trigger if another trigger occurs within the debounceMs. default: 0ms */
  debounceMs?: number;
  /** Time after initialisation that value changes will start to be tracked. default: 0ms  */
  startTrackMs?: number;
};

export function useTrackEvent(eventTracker?: EventTracker) {
  const route = useRoute();
  const appStore = useAppStore();

  const section = inject(sectionTitleKey, undefined);

  function triggerTrackEvent(options: TriggerOptions) {
    const eventData: Dictionary<any> = {
      page: route.meta.name ?? "",
      section: toValue(section),
      local_time: dayjs().format("HH:mm:ss"),
      screen_size: `${window.innerWidth}x${window.innerHeight}`,
      breakpoint: getBreakpoint(window.innerWidth),
    };

    for (const key in options) {
      eventData[key] = toValue(options[key]);
    }

    if (eventTracker) {
      eventTracker(eventData);
    } else if (appStore.logTrackEventsToAws) {
      const { awsRum } = useAwsRum();
      awsRum?.recordEvent("ui-interaction", eventData);
    }

    if (appStore.logTrackEventsToConsole) {
      console.info(`Track Event: ${eventData.name}`, eventData);
    }
  }

  function watchTrackEvent(
    triggerOptions: TriggerOptions,
    { debounceMs, startTrackMs }: WatchOptions = {
      debounceMs: 5000,
      startTrackMs: 1000,
    }
  ) {
    const triggerFn = () => triggerTrackEvent(triggerOptions);

    const debouncedFn = debounceMs
      ? debounce(triggerFn, debounceMs)
      : triggerFn;

    const reactiveTriggerOptions = reactive(triggerOptions);

    const startWatcher = () => {
      // Watch getter functions
      for (const key in triggerOptions) {
        const watchVal = triggerOptions[key as keyof typeof triggerOptions];
        if (typeof watchVal !== "function") continue;

        watch(watchVal, debouncedFn, { flush: "sync" });
      }

      // Watch reactive values
      watch(reactiveTriggerOptions, debouncedFn, { flush: "sync" });
    };

    startTrackMs ? setTimeout(startWatcher, startTrackMs) : startWatcher();
  }

  return { trigger: triggerTrackEvent, watch: watchTrackEvent };
}
