import { useAppStore } from "~/store/app";

export default defineNuxtRouteMiddleware(async (to, _from) => {
  if (import.meta.server) return;

  const { subscription, adminOnly, featureFlag } = to.meta;
  const { isSuperuserOverride, envMeta, featureFlags, subscriptions } =
    storeToRefs(useAppStore());

  if (isSuperuserOverride.value) {
    return;
  }

  const isNotSubscribed =
    subscription && !subscriptions.value[subscription as Subscription];

  if (isNotSubscribed) {
    return navigateTo(`/subscription-needed-${toKebab(subscription)}`);
  }

  const isNotAuthorised = adminOnly;
  const isBehindFeatureFlag =
    featureFlag &&
    !{ ...featureFlags.value, ...envMeta.value.feature_flags }[featureFlag];

  if (isNotAuthorised || isBehindFeatureFlag) {
    return navigateTo("/not-found");
  }
});
