/**
 * Sums all the elements of an array. Treats nulls as zero.
 */
export const sum = (arr: (number | null | undefined)[]) => {
  let output = arr[0] ?? 0;
  const len = arr.length;

  for (let i = 1; i < len; i++) {
    output += arr[i] ?? 0;
  }

  return output;
};
