<template>
  <v-radio-group
    v-bind="{ ...$attrs, ...props }"
    v-model="modelValue"
    class="text-sm"
  >
    <template
      v-for="(slot, index) of slotNames"
      :key="index"
      #[slot]
    >
      <slot :name="slot" />
    </template>
  </v-radio-group>
</template>

<script lang="ts" setup generic="ModelValue">
import { useSlots } from "vue";

import { useTrackEvent } from "@/composables/useTrackEvent";

const props = defineProps<{
  trackEvent: TrackEventProperties | undefined;
}>();

const modelValue = defineModel<ModelValue>({
  required: true,
});

if (props.trackEvent) {
  useTrackEvent().watch({
    value: modelValue,
    element: "radio-group",
    ...props.trackEvent,
  });
}

const slots = useSlots();

// Assert type here to prevent errors in template
const slotNames = Object.keys(slots) as "default"[];
</script>

<style scoped>
:deep(label) {
  font-size: 0.875rem;
}
</style>
