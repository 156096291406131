<template>
  <ClientOnly>
    <v-app>
      <NavDrawer />
      <AppToolbar ref="appBarEl" />
      <v-main v-if="appBarEl">
        <div class="px-1.5 pb-2">
          <NuxtPage />
        </div>
      </v-main>
      <AppSnackbar />
    </v-app>
  </ClientOnly>
</template>

<script lang="ts" setup>
/// <reference lib="dom" />

import favicon from "@/assets/favicon.ico";

import { useAppStore } from "./store/app";

useHead({
  title: "Kayenta",
  link: [{ rel: "icon", type: "image/x-icon", href: favicon }],
});

const appBarEl = ref<HTMLElement>();

if (import.meta.client) {
  const controller = new AbortController();

  window.addEventListener("keydown", (e) => GlobalBus.$emit("keydown", e), {
    signal: controller.signal,
  });
  window.addEventListener("scroll", (e) => GlobalBus.$emit("scroll", e), {
    signal: controller.signal,
  });

  onBeforeUnmount(() => controller.abort());

  const { trigger } = useTrackEvent();
  const sessionStartTime = Date.now();

  const route = useRoute();
  let pageStartTime = 0;
  watch(route, trackPageEnd, { immediate: true });

  function trackPageEnd() {
    const now = Date.now();
    if (pageStartTime === 0) {
      pageStartTime = now;
      return;
    }
    const pageDuration = now - pageStartTime;
    trigger({
      name: "End Page",
      pageDuration,
      pageStartTime,
      pageEndTime: now,
      pagePath: route.path,
    });
    pageStartTime = now;
  }

  window.addEventListener("beforeunload", () => {
    const sessionEndTime = Date.now();
    const sessionDuration = sessionEndTime - sessionStartTime;
    trigger({
      name: "End Session",
      sessionDuration,
      sessionStartTime,
      sessionEndTime,
    });

    trackPageEnd();
  });

  useRemoteSettings(
    pick(storeToRefs(useAppStore()), [
      "selectedView",
      "splitBy",
      "defaultDateRange",
      "isMultisort",
      "showPositionCurrency",
      "tiering",
      "zoomYAxis",
      "showPrecision",
    ]),
    {
      section: "global_settings",
      schema: "global",
      autosave: true,
    }
  );
}
</script>

<style>
@import url("./styles/main.postcss");

@font-face {
  font-family: "MarkOT";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(./assets/fonts/MarkOT-Extlight.otf) format("truetype");
}
@font-face {
  font-family: "MarkOT";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/fonts/MarkOT-Book.otf) format("truetype");
}
@font-face {
  font-family: "MarkOT";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(./assets/fonts/MarkOT-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "MarkOT";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./assets/fonts/MarkOT-Bold.otf) format("truetype");
}

.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
}
</style>
