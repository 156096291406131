// Make sure selectedColumns does not contain any values that are in groupBy

import type { Options } from "@/components/DataTable/dataTableTypes";

// Prevent Column duplication when table is set to ungroup and page view saved.
export function syncUngroupedTableSelectedColumnsWithTableOptions(
  settings: PartialNonNullable<Options>,
  section: string,
  storage: Storage
): PartialNonNullable<Options> {
  const isPositionExplorerPage = [
    "analysis_positions",
    "cash_collateral_positions",
    "stock_loan_positions",
    "swaps_positions",
    "single_security_positions",
  ].includes(section);

  if (!isPositionExplorerPage) return settings;

  const currentToolbarSettings = JSON.parse(
    storage.getItem(`settings:${section}:toolbar`) || "{}"
  ) as PartialNonNullable<Options>;

  // Always remove tableOptions.groupBy columns from toolbar selectedColumns
  const tableSelectedColumns: string[] = settings.selectedColumns ?? [];
  const toolbarGroupBy: string[] = currentToolbarSettings.groupBy ?? [];

  const selectedColumns = tableSelectedColumns.filter(
    (val) => !toolbarGroupBy.includes(val)
  );
  const newToolbarSettings = {
    ...currentToolbarSettings,
    selectedColumns,
  };

  storage.setItem(
    `settings:${section}:toolbar`,
    JSON.stringify(newToolbarSettings)
  );

  return settings;
}
