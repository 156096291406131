<template>
  <div
    class="mr-4 cursor-pointer rounded-full bg-red-100 px-2 py-0.5 text-[0.65rem] text-red-500"
  >
    Beta
    <v-tooltip
      location="top"
      open-delay="400"
      activator="parent"
    >
      <p>This feature is in BETA</p>
      <p>
        It may be using dummy data, and may not be accurate. Changes may occur
        as we gain feedback.
      </p>
    </v-tooltip>
  </div>
</template>
