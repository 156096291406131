/**
 *  Returns an array of numbers with length of stepCount, spread between the start and stop values.
 * @param startValue
 * @param stopValue
 * @param stepCount
 */
export function spreadBetweenRange(
  startValue: number,
  stopValue: number,
  stepCount: number
) {
  if (stepCount === 1) {
    return [(startValue + stopValue) / 2];
  }
  const arr = [];
  const step = (stopValue - startValue) / (stepCount - 1);
  for (let i = 0; i < stepCount; i++) {
    arr.push(startValue + step * i);
  }
  return arr;
}
