export const printFormattedMessage = (
  messages: { text: string; style: string }[]
) => {
  const formattedInfo = messages.reduce(
    (acc, { text, style }, i) => {
      if (i === 0) {
        style += "padding: 1px 8px; border-radius: 4px 0  0 4px;";
      } else if (i === messages.length - 1) {
        style += "padding: 1px 8px; border-radius: 0 4px 4px 0;";
      } else {
        style += "padding: 1px 8px; border-radius: 0;";
      }
      acc.push(style);
      acc[0] += "%c" + text;
      return acc;
    },
    [""] as string[]
  );

  console.info(...formattedInfo);
};
