/**
 * Get the breakpoint based on the width
 * For use when vuetify useDispaly in unavailable
 **/
export const getBreakpoint = (width?: number) => {
  if (width === undefined) return "unknown";
  if (width < 600) return "xs";
  if (width < 960) return "sm";
  if (width < 1264) return "md";
  if (width < 1904) return "lg";
  return "xl";
};
