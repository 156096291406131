import type { Options } from "@/components/DataTable/dataTableTypes";
import type { AppSettings } from "@/store/app";
import { expectsGroupByAndSelectedColumnsToHaveNoOverlap } from "~/utils/migrateSettings/expectsGroupByAndSelectedColumnsToHaveNoOverlap";
import { removeDownloadFormatSetting } from "~/utils/migrateSettings/removeDownloadSettings";
import { removeMarketDataActivityIsQuantity } from "~/utils/migrateSettings/removeMarketDataActivityIsQuantity";
import { removeOldAppSettingsLocal } from "~/utils/migrateSettings/removeOldAppSettingsLocal";
import { removeOverviewWeightedAverageRates } from "~/utils/migrateSettings/removeOverviewWeightedAverageRates";
import { removesPositionsColumns } from "~/utils/migrateSettings/removesPositionsColumns";
import { syncUngroupedTableGroupByWithTableOptions } from "~/utils/migrateSettings/syncUngroupedTableGroupByWithTableOptions";
import { syncUngroupedTableSelectedColumnsWithTableOptions } from "~/utils/migrateSettings/syncUngroupedTableSelectedColumnsWithTableOptions";

import { changeProductIsoToProductIncorporationCountry } from "./changeProductIsoToProductIncorporationCountry";
import { changeSecurityResolverSedolToSedol } from "./changeSecurityResolverSedolToSedol";
import { changeSedolToProductSedol } from "./changeSedolToProductSedol";
import { updateDefaultDateRange } from "./updateDefaultDateRange";
import { updateDefaultViews } from "./updateDefaultViews";
import { updateNumberFiltersPrecision } from "./updateNumberFiltersPrecision";
import { updatePrecisionSettings } from "./updatePrecisionSettings";

export type Validator<T> = (
  settings: PartialNonNullable<T>,
  section: string,
  storage: Storage
) => PartialNonNullable<T>;

const appValidators: {
  global: Validator<AppSettings>[];
  table: Validator<Options>[];
  toolbar: Validator<Dictionary>[];
} = {
  global: [
    removeOldAppSettingsLocal,
    removeDownloadFormatSetting,
    updateDefaultViews,
    updatePrecisionSettings,
    updateDefaultDateRange,
  ],
  table: [
    expectsGroupByAndSelectedColumnsToHaveNoOverlap,
    removesPositionsColumns,
    syncUngroupedTableGroupByWithTableOptions,
    syncUngroupedTableSelectedColumnsWithTableOptions,
    updateNumberFiltersPrecision,
    changeSecurityResolverSedolToSedol,
    changeSedolToProductSedol,
    changeProductIsoToProductIncorporationCountry,
  ],
  toolbar: [
    removeOverviewWeightedAverageRates,
    removeMarketDataActivityIsQuantity,
  ],
};

export function migrateSettings(
  settings: string,
  schema: string,
  section: string,
  storage: Storage = window.localStorage,
  validators: Dictionary<Validator<any>[]> = appValidators
): Dictionary {
  try {
    return validators[schema].reduce(
      (prev, validator) => validator(prev, section, storage),
      JSON.parse(settings) as Dictionary
    );
  } catch {
    return {};
  }
}
