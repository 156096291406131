<template>
  <v-switch
    v-bind="$attrs"
    v-model="modelValue"
  >
    <template
      v-for="(slot, index) of slotNames"
      :key="index"
      #[slot]
    >
      <slot :name="slot" />
    </template>
  </v-switch>
</template>

<script lang="ts" setup>
import { useSlots } from "vue";
import type { ComponentProps } from "vue-component-type-helpers";
import { VSwitch } from "vuetify/lib/components/index.mjs";

import { useTrackEvent } from "@/composables/useTrackEvent";

type VSwitchProps = ComponentProps<typeof VSwitch>;
const props = defineProps<{
  trackEvent: TrackEventProperties | undefined;
}>();

const slots = useSlots();

// Assert type here to prevent errors in template
const slotNames = Object.keys(slots) as "default"[];

const modelValue = defineModel<VSwitchProps["modelValue"]>({
  required: true,
});

if (props.trackEvent) {
  useTrackEvent().watch({
    value: modelValue,
    element: "switch",
    ...props.trackEvent,
  });
}
</script>
