import type { Options } from "@/components/DataTable/dataTableTypes";

export function changeProductIsoToProductIncorporationCountry(
  settings: PartialNonNullable<Options>
) {
  return {
    ...settings,
    selectedColumns: settings.selectedColumns.map((column) =>
      column === "productIso" ? "productIncorporationCountry" : column
    ),
  };
}
