interface ObservedCell extends HTMLElement {
  _resizeObserver: ResizeObserver;
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive("resize-observer", {
    created(el, binding) {
      if (binding.value) {
        const observer = new ResizeObserver(binding.value);
        observer.observe(el);

        (<ObservedCell>el)._resizeObserver = observer;
      }
    },
    unmounted(el) {
      (<ObservedCell>el)._resizeObserver?.disconnect();
    },
  });
});
