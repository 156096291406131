import type { Options } from "@/components/DataTable/dataTableTypes";
import { precisionTypes } from "~/store/app.types";

export function updateNumberFiltersPrecision(
  settings: PartialNonNullable<Options>
) {
  const { columnFilters } = settings;

  if (!columnFilters) return settings;

  Object.keys(columnFilters).forEach((key) => {
    const precisionType = columnFilters[key]?.state?.precisionType;
    // @ts-ignore
    const precision = precisionTypes[precisionType];

    if (precision) {
      delete columnFilters[key].state.precisionType;
      // @ts-ignore
      columnFilters[key].state.precision = precisionTypes[precisionType];
    }
  });

  return { ...settings, columnFilters };
}
